.upload-works {
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  .image {
    width: 1.2rem;
    height: 1.2rem;
    object-fit: cover;
    border-radius: .06rem;
  }
  .input {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
