.my-interest-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: 1.02rem .16rem 0;
  .search-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: .62rem .16rem .10rem;
    background-color: #100D20;
    .form-super {
      display: flex;
      justify-content: space-between;
      position: relative;
      .adm-input {
        width: 3rem;
        height: .30rem;
        background: #1E1E23;
        border-radius: .06rem;
        padding-left: .41rem;
        .adm-input-element {
          font-size: .12rem;
          font-weight: 400;
          color: #FFFFFF;
          &::placeholder {
            color: #979797;
          }
        }
      }
      .icon-search {
        width: .14rem;
        height: .14rem;
        position: absolute;
        left: .12rem;
        top: .09rem;
      }
      .search-btn {
        font-size: .16rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .30rem;
      }
    }
  }
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
    }
  }
  .product-list {
    .item {
      padding: .10rem .12rem;
      background: #1E1E23;
      border-radius: .06rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .10rem;
      &:last-child {
        margin-bottom: 0;
      }
      .info-super {
        display: flex;
        align-items: center;
        flex: 1;
        .main-img {
          width: .54rem;
          height: .54rem;
          object-fit: cover;
          border-radius: .06rem;
          margin-right: .12rem;
        }
        .info {
          flex: 1;
          width: 0;
          .name {
            font-size: .14rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .20rem;
            padding-top: .03rem;
            padding-bottom: .05rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .price {
            font-size: .16rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .22rem;
          }
        }
      }
      .count {
        font-size: .12rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .17rem;
      }
    }
  }
}