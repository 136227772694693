.welfare-detail-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: .50rem .16rem 0;
  .banner {
    background-color: #1E1C28;
    border-radius: .06rem;
    .banner-picture {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: .06rem;
    }
    .banner-time {
      padding: .15rem .12rem;
      font-size: .14rem;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
    }
  }
  .section {
    padding-top: .20rem;
    margin-bottom: .6rem;
    .section-title {
      font-size: .18rem;
      font-weight: 600;
      color: #FFFFFF;
    }
    .section-content {
      padding: .15rem .10rem;
      background: #1E1E23;
      border-radius: .06rem;
      margin-top: .15rem;
      font-size: .14rem;
      line-height: .2rem;
    }
  }
  .footer {
    width: 100%;
    height: .50rem;
    position: fixed;
    background: #100D20;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .16rem;
    .price {
      font-size: .18rem;
      font-weight: 600;
      color: #FFFFFF;
    }
    .button {
      width: 1.2rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: .14rem;
      font-weight: 600;
      color: #FFFFFF;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
      }
    }
  }
}
