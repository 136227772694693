.confirm-subgift-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .16rem .50rem;
  .collection-super {
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    padding: .15rem .12rem;
    display: flex;
    margin-bottom: .30rem;
    .main-pic {
      width: 1.00rem;
      height: 1.00rem;
      margin-right: .20rem;
    }
    .content {
      width: 0;
      flex: 1;
      padding-top: .01rem;
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      .name {
        font-size: .16rem;
        font-weight: 600;
        line-height: .22rem;
        padding-bottom: .05rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text {
        padding-top: .05rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .tip-super {
    background: #1E1C28;
    border-radius: .06rem;
    padding: .20rem .12rem;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .20rem;
    margin-bottom: .66rem;
    .title {
      font-size: .16rem;
      font-weight: 600;
      line-height: .22rem;
    }
    .text {
      padding-top: .15rem;
    }
  }
  .btn-confirm {
    width: 3.19rem;
    height: .45rem;
    margin: 0 auto;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    background-size: 100% 100%;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .45rem;
    text-align: center;
  }
  .tip-text {
    font-size: .12rem;
    font-weight: 400;
    color: #979797;
    line-height: .17rem;
    text-align: center;
    padding-top: .15rem;
  }
  .input-password-super {
    padding: .50rem 0;
    background: #1E1C28;
    border-radius: .20rem .20rem 0 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .25rem;
      right: .28rem;
    }
    .content {
      user-select: none;
      .title {
        font-size: .20rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .28rem;
        text-align: center;
        padding-bottom: .43rem;
      }
      .box-list {
        width: 100%;
        height: .46rem;
        padding: 0 .28rem;
        display: flex;
        justify-content: space-between;
        .box-item {
          width: .46rem;
          height: 100%;
          background: #EDEDED;
          border-radius: .06rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: .12rem;
            height: .12rem;
            background-color: #000000;
            border-radius: .06rem;
            &.hide {
              display: none;
            }
          }
        }
      }
    }
  }
  .password-value-input {
    position: fixed;
    left: 0;
    bottom: -1000rem;
  }
}