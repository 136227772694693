.password-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding-top: 1.39rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .password-icon {
    width: .50rem;
    height: .50rem;
    margin-bottom: .26rem;
  }
  .password-done {
    width: .69rem;
    height: .50rem;
    margin-bottom: .26rem;
  }
  .password-title {
    font-size: .20rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .28rem;
    padding-bottom: .20rem;
  }
  .password-tip {
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .20rem;
    padding-bottom: .43rem;
  }
  .password-btn {
    width: 2.82rem;
    height: .45rem;
    line-height: .45rem;
    text-align: center;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    background-size: 100% 100%;
  }
}