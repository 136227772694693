.agree-container {
  position: relative;
  padding: .44rem 0 .20rem;
  background-color: #ffffff;
  .agree-main-img {
    width: 100%;
  }
  .useage-link {
    width: 3.50rem;
    height: .45rem;
    position: absolute;
    top: 44.87rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .sdk-link {
    width: 3.50rem;
    height: .45rem;
    position: absolute;
    top: 114.84rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
