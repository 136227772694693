.my-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .30rem 0 .50rem;
  .my-container-header {
    text-align: right;
    padding-right: .16rem;
    .my-container-message {
      width: .23rem;
      height: .23rem;
    }
    .my-container-service {
      width: .2rem;
      height: .22rem;
      margin-left: .13rem;
    }
  }
  .my-container-name {
    width: 100%;
    padding: .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name-img {
      width: .5rem;
      height: .5rem;
      object-fit: cover;
      border-radius: 50%;
    }
    .name-title {
      width: 2.8rem;
      position: relative;
      .name-title-top {
        font-size: .2rem;
        font-weight: 600;
        margin-bottom: .04rem;
        display: flex;
        align-items: center;
        .nickname {
          max-width: 1.9rem;
          padding-right: .11rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .medal {
          width: .36rem;
          height: .24rem;
        }
      }
      .name-title-bottom {
        font-size: .1rem;
        padding-right: .2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
      }
      .name-qukuailian {
        width: .14rem;
        height: .14rem;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .my-container-autonyms {
    width: 100%;
    height: .3rem;
    padding: 0 .16rem;
    .my-container-autonym {
      width: 100%;
      height: .3rem;
      background: #1E1C28;
      border-radius: .06rem;
      padding: 0 .12rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: .1rem;
      .authentication {
        width: .45rem;
        height: .2rem;
      }
    }
  }
  .my-container-center {
    width: 100%;
    padding: .15rem .16rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 1.64rem;
      height: 1.64rem;
      margin-bottom: .15rem;
      background: #1E1C28;
      border-radius: .06rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .my-container-icon-img {
        width: .4rem;
        margin-bottom: .2rem;
      }
      .my-container-center-text {
        color: #FFFF;
        font-size: .12rem;
      }
    }
  }
  .modal-contact-content {
    margin: 1.34rem auto 0;
    width: 2.52rem;
    height: 3.06rem;
    background: #1E1C28;
    border-radius: .06rem;
    opacity: 0.9;
    text-align: center;
    .mask-title {
      font-size: .16rem;
      font-weight: 600;
      padding-top: .48rem;
    }
    .mask-img {
      width:  1.2rem;
      height: 1.2rem;
      object-fit: contain;
      margin-top: .38rem;
    }
    .mask-hint {
      font-size: .14rem;
      color: #979797;
      margin-top: .1rem;
    }
  }
}