.agree-baofu-container {
  position: relative;
  background: #FFFFFF;
  padding: .44rem .16rem .50rem;
  .agree-title {
    font-size: .16rem;
    font-weight: 600;
    color: #000000;
    line-height: .22rem;
    padding: .22rem 0 .17rem;
  }
  .agree-img {
    width: 100%;
  }
}