.announce-detail-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: .50rem .16rem 0;

  .detail-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .15rem;
    .detail-time-img {
      width: .14rem;
      height: .14rem;
    }

    .detail-time-text {
      font-size: .14rem;
      color: #979797;
      font-weight: 400;
      margin-left: .10rem;
    }
  }

  .section {
    padding: .15rem .12rem;
    background: #1E1C28;
    border-radius: .06rem;
    height: calc(100vh - 1.09rem);
    .section-content {
      height: calc(100vh - 2rem);
      font-size: .14rem;
      line-height: .2rem;
      overflow: auto;
    }
  }

  .submit-super {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: .20rem 0 .34rem;

    .submit-btn {
      width: 3.19rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
    }
  }
}