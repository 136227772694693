.collection-detail-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 .16rem .65rem;
  color: #ffffff;
  .banner {
    text-align: center;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-banner-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: .44rem;
    .banner-picture {
      width: 100%;
      height: 2.2rem;
      object-fit: contain;
      margin-top: .15rem;
    }
    .banner-icon {
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-banner-bottom.png);
      background-size: 2.92rem .90rem;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: .90rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: .18rem;
      .icon-3d {
        width: .20rem;
        height: .20rem;
      }
      .icon-play {
        width: .19rem;
        height: .23rem;
        margin-left: .20rem;
      }
    }
    .banner-name {
      font-size: .2rem;
      font-weight: 600;
      padding-bottom: .15rem;
    }
  }
  .section {
    width: 100%;
    padding: .15rem .12rem;
    margin-top: .15rem;
    background: #1E1E23;
    border-radius: .06rem;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .14rem;
      padding-bottom: .15rem;
      &:last-child {
        padding-bottom: 0;
      }
      .value {
        color: #979797;
      }
      .hash-super {
        display: flex;
        align-items: center;
        .hash {
          color: #979797;
          text-align: right;
          padding-right: .10rem;
        }
        .icon-copy {
          width: .14rem;
          height: .14rem;
        }
      }
    }
    .title {
      font-size: .18rem;
      font-weight: 600;
    }
    .video-super {
      width: 100%;
      height: 1.76rem;
      margin-top: .15rem;
      position: relative;
      .poster {
        width: 100%;
        height: 1.76rem;
        border-radius: .06rem;
        object-fit: cover;
      }
      .icon {
        width: .20rem;
        height: .25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .story {
      padding-top: .15rem;
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      word-break: break-all;
    }
  }
  .support-super {
    padding-top: .36rem;
  }
  .nav-list {
    width: 100%;
    height: .50rem;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 .20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .20rem;
    .item {
      display: flex;
      align-items: center;
      .icon-shining {
        width: .15rem;
        height: .20rem;
        margin-right: .10rem;
      }
      .icon-give {
        width: .19rem;
        height: .20rem;
        margin-right: .10rem;
      }
      .icon-certificate {
        width: .19rem;
        height: .20rem;
        margin-right: .10rem;
      }
      .icon-use {
        width: .18rem;
        height: .18rem;
        margin-right: .10rem;
      }
    }
    .create-avatar {
      width: 1.07rem;
      height: .35rem;
      background: #000000;
      border-radius: .02rem;
      position: absolute;
      right: 0;
      top: -.45rem;
      padding-left: .11rem;
      display: flex;
      align-items: center;
      .icon-avatar {
        width: .18rem;
        height: .20rem;
        margin-right: .11rem;
      }
    }
  }
  .give-tip-super {
    padding: .55rem .28rem .40rem;
    background: #1E1C28;
    border-radius: .20rem .20rem 0 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .30rem;
      right: .28rem;
    }
    .title {
      font-size: .20rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
      text-align: center;
      padding-bottom: .10rem;
    }
    .desc {
      font-size: .16rem;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      padding-bottom: .30rem;
    }
    .tip-title {
      font-size: .14rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .15rem;
    }
    .tip-text {
      font-size: .12rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .17rem;
      padding-bottom: .10rem;
    }
    .btn {
      width: 1.20rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .14rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .35rem;
      text-align: center;
      margin: .30rem auto 0;
    }
  }
}
