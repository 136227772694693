.product-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 .16rem .65rem;
  color: #ffffff;
  .icon-share {
    width: .16rem;
    height: .14rem;
    position: fixed;
    top: .14rem;
    right: .16rem;
    z-index: 1001;
  }
  .banner {
    text-align: center;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-banner-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: .44rem;
    .banner-picture {
      width: 100%;
      height: 2.2rem;
      object-fit: contain;
    }
    .banner-icon {
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-banner-bottom.png);
      background-size: 2.92rem .90rem;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: .90rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: .18rem;
      .icon-3d {
        width: .20rem;
        height: .20rem;
      }
      .icon-play {
        width: .19rem;
        height: .23rem;
        margin-left: .20rem;
      }
    }
    .banner-name {
      font-size: .2rem;
      font-weight: 600;
    }
    .banner-subtitle {
      margin-top: .1rem;
      font-size: .11rem;
      .banner-quantity {
        background: linear-gradient(127deg, #A0F0FF 0%, #5237FA 100%);
        border-radius: .01rem 0 0 .01rem;
        padding: .02rem .05rem;
        font-weight: 600;
        color: #06213B;
      }
      .banner-part {
        background: #3B3348;
        border-radius: 1px 0px 0px 1px;
        padding: .02rem .08rem .02rem .05rem;
        margin-right: .05rem;
      }
      .banner-describe0 {
        background: linear-gradient(139deg, #A0F0FF 0%, #5237FA 100%);
        border-radius: .01rem;
        margin-right: .05rem;
        padding: .02rem .06rem;
        color: #06213B;
        font-weight: 600;
      }
      .banner-describe1 {
        background: #3B3348;
        border-radius: .01rem;
        margin-right: .05rem;
        padding: .02rem .06rem;
      }
    }
    .banner-prompt {
      margin-top: .1rem;
      color: #6E6B7C;
      font-size: .12rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .dotted-left {
        width: .92rem;
        border: .01rem dashed #6E6B7C;
      }
      .lock {
        width: .12rem;
        height: .14rem;
      }
      .dotted-right {
        width: .92rem;
        border: .01rem dashed #6E6B7C;
      }
    }
  }
  .author-super {
    margin-top: .20rem;
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    display: flex;
    align-items: center;
    padding: .12rem;
    .author-icon {
      width: .4rem;
      height: .4rem;
      border-radius: .06rem;
    }
    .author-title {
      margin-left: .1rem;
      font-size: .14rem;
      .name {
        font-size: .12rem;
      }
    }
  }
  .sale-super {
    padding: .12rem;
    background: #1E1E23;
    border-radius: .06rem;
    margin-top: .15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .02rem;
    }
    .subtitle {
      font-size: .12rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .17rem;
    }
    .icon-arrow {
      width: .09rem;
      height: .15rem;
      transform: rotate(180deg);
    }
  }
  .progress {
    margin-top: .15rem;
    padding: .08rem .12rem .16rem;
    width: 100%;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/product-progress-bg.png);
    background-size: 100% 100%;
    position: relative;
    .my-collection {
      font-size: .18rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .25rem;
    }
    .my-img {
      padding-top: .14rem;
      .img {
        width: .24rem;
        height: .29rem;
        margin-right: .08rem;
        &:last-child {
          margin: 0;
        }
      }
    }
    .look-btn {
      position: absolute;
      top: .05rem;
      right: .11rem;
      font-size: .10rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .14rem;
      display: flex;
      align-items: center;
      .arrow {
        width: .06rem;
        height: .06rem;
        margin-left: .05rem;
      }
    }
  }
  .introduce {
    margin-top: .15rem;
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    padding-left: .12rem;
    padding-right: .12rem;
    padding-bottom: .15rem;
    .introduce-title {
      font-size: .18rem;
      font-weight: 600;
      padding-top: .15rem;
    }
    .video-super {
      width: 100%;
      height: 1.76rem;
      margin-top: .1rem;
      position: relative;
      .poster {
        width: 100%;
        height: 1.76rem;
        border-radius: .06rem;
        object-fit: cover;
      }
      .icon {
        width: .20rem;
        height: .25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .introduce-desc {
      width: 100%;
      overflow: auto;
      img {
        width: 100%;
      }
    }
    .introduce-publish {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: .15rem;
      font-size: .14rem;
      .publish-right {
        color: #979797;
      }
    }
  }
  .notice {
    margin-top: .15rem;
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    padding: .15rem .12rem;
    .notice-title {
      font-size: .18rem;
      font-weight: 600;
    }
    .notice-contet {
      margin-top: .15rem;
      font-size: .14rem;
      line-height: .2rem;
    }
  }
  .create-title {
    padding: .15rem 0;
    font-size: .18rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .25rem;
  }
  .create-section {
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    padding: .15rem .12rem;
    .info-list {
      .item {
        display: flex;
        justify-content: space-between;
        padding-bottom: .15rem;
        &:last-child {
          padding-bottom: 0;
        }
        .label {
          font-size: .14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .20rem;
        }
        .value {
          font-size: .14rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .20rem;
          width: 1.76rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon-copy {
            width: .14rem;
            height: .14rem;
            margin-left: .05rem;
          }
        }
      }
    }
    .desc-list {
      .item {
        font-size: .14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: .20rem;
        padding-bottom: .15rem;
        &:last-child {
          padding-bottom: 0;
        }
        .label {
          color: #FFFFFF;
          padding-bottom: .05rem;
        }
        .value {
          color: #979797;
        }
      }
    }
    .create-notice {
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
    }
    table {
      width: 100%;
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
      tr {
        line-height: .20rem;
        th {
          color: #979797;
        }
        td {
          color: #FFFFFF;
          padding-top: .15rem;
        }
      }
    }
    .record-enpty {
      width: 100%;
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
      text-align: center;
      padding-top: .20rem;
    }
  }
  .support-super {
    padding-top: .36rem;
  }
  .footer {
    width: 100%;
    height: .50rem;
    position: fixed;
    background: #100D20;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .16rem;
    .price {
      font-size: .18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .botton-super {
      display: flex;
      align-items: center;
      .icon-interest {
        width: .22rem;
        height: .20rem;
        margin-right: .14rem;
      }
      .icon-option {
        width: .21rem;
        height: .20rem;
        margin-right: .14rem;
      }
    }
    .button {
      width: 1.2rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
      }
      .big-font {
        font-size: .14rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
      .small-font {
        font-size: .12rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
      .residue {
        font-size: .10rem;
        font-weight: 400;
        .rv-count-down {
          font-size: .10rem;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          line-height: 1em;
        }
      }
    }
  }
  .token-list-modal {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #1E1C28;
    border-radius: .06rem;
    padding: .20rem .28rem .76rem;
    .title-super {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .15rem;
      .title {
        font-size: .16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .22rem;
      }
      .icon-close {
        width: .14rem;
        height: .14rem;
      }
    }
    .list-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .10rem;
      border-bottom: 1px solid rgba(151, 151, 151, 0.20);
      .account {
        font-size: .14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: .20rem;
      }
      .btn-super {
        display: flex;
        .sort-super {
          font-size: .14rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .20rem;
          margin-left: .10rem;
          display: flex;
          align-items: center;
          &.active {
            color: #88B6FD;
          }
          .icon {
            width: .14rem;
            height: .14rem;
            margin-left: .05rem;
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sort-btn-default.png);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: top center;
            &.up {
              background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sort-btn-up.png);
            }
            &.down {
              background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-sort-btn-down.png);
            }
          }
        }
      }
    }
    .list-content {
      padding-top: .14rem;
      overflow: auto;
      height: 3.00rem;
      .item {
        display: flex;
        justify-content: space-between;
        font-size: .14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .20rem;
        padding-bottom: .09rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        margin-bottom: .15rem;
        &.active .number {
          color: #88B6FD;
        }
        .price {
          display: flex;
          align-items: center;
          .icon-arrow {
            width: .09rem;
            height: .15rem;
            transform: rotate(180deg);
            margin-left: .10rem;
          }
        }
      }
    }
  }
  .more-option-modal {
    width: 100%;
    position: fixed;
    background: #1E1E23;
    border-radius: .06rem .06rem 0 0;
    left: 0;
    bottom: 0;
    .icon-close {
      width: .10rem;
      height: .10rem;
      position: absolute;
      top: .15rem;
      right: .16rem;
    }
    .option-item {
      width: 100%;
      padding: .15rem .28rem;
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(151, 151, 151, 0.20);
      &:last-child {
        border-bottom: 0;
      }
      .icon {
        width: .20rem;
        height: .20rem;
        margin-right: .10rem;
      }
    }
  }
  .select-no-modal {
    width: 100%;
    position: fixed;
    background: #1E1C28;
    border-radius: .20rem .20rem 0 0;
    left: 0;
    bottom: 0;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .17rem;
      right: .28rem;
    }
    .content-super {
      padding: .27rem .16rem .20rem;
      .title {
        font-size: .16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .22rem;
        padding-bottom: .15rem;
      }
      .no-list {
        display: grid;
        align-content: start; 
        grid-template-columns: repeat(4, 1fr);
        grid-gap: .15rem;
        min-height: 1.80rem;
        .item {
          width: 100%;
          height: .24rem;
          border: 1px solid #FFFFFF;
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .24rem;
          text-align: center;
          border-radius: .06rem;
          &.checked {
            border: 0;
            background: linear-gradient(139deg, #9DE9FF 0%, #7488FC 100%);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
        }
      }
    }
    .btn-super {
      width: 100%;
      height: .50rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 .16rem;
      background: #100D20;
      .price {
        font-size: .18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .btn-buy {
        width: 1.20rem;
        height: .35rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
        background-size: 100% 100%;
        line-height: .35rem;
        font-size: .14rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
        &.disable {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
        }
      }
    }
  }
}
