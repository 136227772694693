.sale-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #FBFBFB;
  padding-top: 1.49rem;
  .entrance-publish {
    position: fixed;
    right: .10rem;
    bottom: 1.07rem;
    width: .68rem;
    height: .68rem;
    z-index: 100;
  }
  .top-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.00rem;
    z-index: 1002;
  }
  .sale-record {
    position: fixed;
    top: .10rem;
    right: .16rem;
    font-size: .14rem;
    font-weight: 400;
    color: #4095E5;
    line-height: .20rem;
    z-index: 1001;
  }
  .banner-super {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #FBFBFB;
    padding-top: 0.62rem;
  }
  .form-super {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 .16rem;
    .adm-input {
      width: 3rem;
      height: .30rem;
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      padding-left: .41rem;
      .adm-input-element {
        font-size: .12rem;
        font-weight: 400;
        color: #333333;
        &::placeholder {
          color: #DEDEDE;
        }
      }
      .adm-input-clear {
        padding-right: .20rem;
      }
    }
    .icon-search {
      width: .14rem;
      height: .14rem;
      position: absolute;
      left: .28rem;
      top: .09rem;
    }
    .search-btn {
      font-size: .16rem;
      font-weight: 400;
      color: #333333;
      line-height: .30rem;
    }
  }
  .condition-super {
    padding: .12rem .25rem .23rem .33rem;
    display: flex;
    justify-content: space-between;
    .item {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: .20rem;
      display: flex;
      align-items: center;
      position: relative;
      &.active {
        color: #7387FC;
      }
      .icon {
        width: .12rem;
        height: .06rem;
        margin-left: .05rem;
      }
      .drop-super {
        width: .80rem;
        border-radius: .06rem;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
        text-align: center;
        position: absolute;
        top: .30rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        .option {
          padding: .10rem 0;
          font-size: .14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: .20rem;
          border-bottom: 1px solid rgba(151, 151, 151, 0.20);
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .content-super {
    position: relative;
    min-height: calc(100vh - 1.49rem);
    padding-bottom: .20rem;
    .content-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: rgba(153, 153, 153, 0.60);
    }
    .enpty-super {
      padding-top: 1.61rem;
      text-align: center;
      .enpty-img {
        width: 2.14rem;
        height: 1.24rem;
        opacity: .5;
      }
      .text {
        padding-top: .41rem;
        font-size: .16rem;
        font-weight: 400;
        color: #666666;
        line-height: .22rem;
      }
    }
    .sale-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: .10rem;
      padding: 0 .16rem;
      position: relative;
      .item {
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
        border-radius: .06rem;
        padding: .10rem;
        min-width: 0;
        .main-pic {
          width: 1.46rem;
          height: 1.46rem;
          object-fit: cover;
          border-radius: .06rem;
        }
        .name {
          font-size: .14rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: .20rem;
          padding-top: .10rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .price {
          font-size: .16rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4095E5;
          padding-top: .05rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .yuan {
            font-size: .12rem;
            padding-right: .05rem;
          }
        }
      }
    }
  }
}
