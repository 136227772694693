.setting-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .25rem 0 .16rem;
  .setting-container-list {
    margin-top: .18rem;
    margin-bottom: .3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      flex: 1;
      .upload-block {
        margin: initial;
      }
      .left-icon-img {
        height: .5rem;
        width: .5rem;
      }
      .left-text {
        font-size: .14rem;
        color: #979797;
        position: relative;
        .dot-icon-img {
          width: .06rem;
          height: .06rem;
          position: absolute;
          left: -.12rem;
          top: 50%;
          transform: translate(0, -50%)
        }
      }
      .right-icon-img {
        margin-left: .1rem;
        height: .1rem;
      }
      .input {
        position: absolute;
        width: .5rem;
        height: .5rem;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
  .setting-container-log {
    margin-top: .74rem;
    padding-left: .13rem;
    padding-right: .03rem;
    .log-out {
      width: 3.20rem;
      height: .46rem;
    }
  }
}
