.invite-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .44rem .16rem 0;
  .invite-list {
    width: .48rem;
    height: .17rem;
    position: fixed;
    top: .14rem;
    right: .16rem;
    z-index: 1001;
    font-size: .12rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .17rem;
  }
  .poster-super {
    width: 100%;
    height: 5.14rem;
    .poster-img {
      width: 100%;
      height: 5.14rem;
    }
  }
  .poster-tip {
    font-size: .14rem;
    font-weight: 600;
    color: #999999;
    line-height: .20rem;
    text-align: center;
    padding-top: .20rem;
  }
  .invite-canvas {
    position: absolute;
    top: -99999rem;
    left: -99999rem;
  }
  .btn-super {
    display: flex;
    justify-content: center;
    padding: .53rem 0 .20rem;
    .btn-copy {
      width: 1.20rem;
      height: .35rem;
      line-height: .35rem;
      text-align: center;
      font-size: .14rem;
      font-weight: 400;
      color: #000000;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/invite-btn-bg.png);
      background-size: 100% 100%;
    }
  }
}