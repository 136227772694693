.rule-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #ffffff;
  padding: .64rem .16rem 0;
  .section {
    padding-bottom: .30rem;
    font-size: .13rem;
    font-weight: 400;
    color: #666666;
    line-height: .18rem;
    .title {
      font-size: .16rem;
      font-weight: 500;
      color: #333333;
      line-height: .22rem;
      padding-bottom: .10rem;
    }
    .intro {
      line-height: .20rem;
    }
    .desc {
      line-height: .25rem;
    }
    .qr-list {
      padding: .15rem 0 0 .19rem;
      display: flex;
      .item {
        text-align: center;
        margin-right: .29rem;
        .qr {
          width: 1.20rem;
          height: 1.20rem;
          padding-bottom: .10rem;
        }
      }
    }
  }
}