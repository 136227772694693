.creator-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .69rem .16rem .50rem;
  .search-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: .20rem .16rem .19rem;
    background-color: #100D20;
    .form-super {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .adm-input {
        height: .30rem;
        background: #1E1E23;
        border-radius: .06rem;
        padding-left: .41rem;
        .adm-input-element {
          font-size: .12rem;
          font-weight: 400;
          color: #FFFFFF;
          &::placeholder {
            color: #979797;
          }
        }
      }
      .icon-search {
        width: .14rem;
        height: .14rem;
        position: absolute;
        left: .12rem;
        top: .09rem;
      }
      .icon-publish {
        width: .24rem;
        height: .24rem;
        margin-left: .18rem;
      }
    }
  }
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
    }
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: .10rem;
    .list {
      min-width: 0;
      .item {
        width: 100%;
        background: #1E1E23;
        border-radius: .06rem;
        margin-bottom: .10rem;
        &:last-child {
          margin-bottom: 0;
        }
        .main-img {
          width: 100%;
          border-radius: .06rem;
          vertical-align: bottom;
        }
        .info-super {
          padding: .08rem .12rem;
          .name {
            font-size: .16rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .22rem;
            padding-bottom: .08rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .data-super {
            display: flex;
            justify-content: space-between;
            font-size: .14rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .20rem;
            .hot-super {
              display: flex;
              align-items: center;
              .icon {
                width: .10rem;
                height: .12rem;
                margin-right: .05rem;
              }
            }
          }
        }
      }
    }
  }
}