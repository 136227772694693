.agree-container {
  .secret-link {
    width: 3.50rem;
    height: .45rem;
    position: absolute;
    top: 65.13rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
