.lottery-container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-page-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  .lottery-super {
    width: 2.33rem;
    margin: 0 auto;
    .lottery {
      position: relative;
      overflow: hidden;
      #balls {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        border-radius: 50%;
      }
      .cover {
        position: relative;
        z-index: 0;
        display: block;
        overflow: hidden;
        width: 100%;
      }
      .cap {
        width: 1.70rem;
        position: absolute;
        bottom: 0.20rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .base {
      position: relative;
      top: -.35rem;
      z-index: 999;
      padding-top: .30rem;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
        width: 100%;
        &.mask {
          -webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlIAAAGVCAMAAAACBO/QAAAAY1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmaHTeAAAAIXRSTlP/+PDo4NjQyMC4sKigmJCIgHhwaGBYUEhAODAoAAgQGCByIkh2AAADJElEQVR4Ae3d1ZUDVhBEwV5mRkkjyD9Ks78NvVwVw12cd1oBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOA7uy24DPxtGo5ThaReA3+ZiotANandXuAP03EbqCa1Pgz8bkqeA7+bltNANanVXuBXU3MdqCa1OQgk0/MQSKboOJApeg1kmi4D07TbD5KquguSqnLqC9Pl1MeUnaUJSTn1MV1OfUyXUx9T95geJOXUx/QtgqS6LlOFpLb7QVJVd6lCUuujIKmql3QgKac+psupj3kjN2lAUk59TJdTH/NmToKkPt2pD0k59TFdTn3MG7oPkvpkpz4k5dTHvKnzIKlPdepDUk59TJdTH9Pl1Md0OfUxb20ZJPV5Tn1IyqmP6XLqY7qc+pgupz6my6mP6fJp2kyXUx/TZWKfeR9PQVJdp0FSVcsgqa6rIKmqzX6QVNV9kFTXcZBU1X2QVNVJkFTTa5BU1XmQVNMqnxj+1YmkNntBUk23QVJN64MgqabHIKmq4yCpppcgqaqzIKmmZZBU1WWQVNN2L0iq6SZUWW7ZzyeGfSkkdRgk1fQcJFV1EiTVtAiSqroIkmraBUlVXQdJGdf4xCR1l58G/+akzEQnZZ6cU2ZZgzLLGpRZ1qDMsgZlljUos6xBmWUNyixrUDZv5ig/Evdryxp0HTysfVoaXYePljU+O1FZ1uDoybIGZcfP9WUNRFVe1oCTl/KyBpy+1p+cI6rysgacLcrLGnC+rC9rIKryk3O4WJWXNeBy113WgL3L3ccvayCqu0AzqvVhoBnVY6D6i/pxoBnVa6Aa1Vngn7pcvfuyBqK6CtSi6j85R1S3gVpU/WUNRPUQqEZ1FGhG9RyoRnUaaEa1DFSjugw0o9rtBSouVvVlDURVf3KOqO4DVfsBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvolfAKNB7Qyhy6VaAAAAAElFTkSuQmCC');
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-position: 0 0;
          -webkit-mask-size: cover;
        }
      }
      .award {
        position: absolute;
        top: .90rem;
        left: 1rem;
        width: .40rem;
        height: auto;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        &.rolling {
          -webkit-animation-name: rolling;
          animation-name: rolling;
          -webkit-animation-duration: 600ms;
          animation-duration: 600ms;
          -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
        }
      }
      .one {
        width: .50rem;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .turn {
          display: block;
          width: .50rem;
          margin-bottom: .10rem;
          transition: all 600ms ease-in-out;
          &.active {
            transform: rotate(90deg);
          }
        }
        .btn {
          width: .46rem;
          height: .16rem;
          border-radius: .07rem;
          background-color: #ffd6d6;
          color: #ff5c65;
          font-size: .10rem;
          line-height: .16rem;
        }
      }
    }
  }
  .carousel-super {
    position: absolute;
    top: .78rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.30rem;
    height: .38rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-carousel-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 .21rem;
    display: flex;
    align-items: center;
    .icon {
      width: .20rem;
      height: .18rem;
      padding-right: .08rem;
    }
    .text-super {
      flex: 1;
      overflow: hidden;
      height: .38rem;
      position: relative;
      .text {
        position: absolute;
        top: 0;
        left: 0;
        font-size: .14rem;
        font-weight: 600;
        color: #FF5C65;
        line-height: .38rem;
        white-space: nowrap;
      }
    }
  }
  .button-super {
    position: absolute;
    top: 1.36rem;
    right: 0;
    display: flex;
    flex-direction: column;
    .btn {
      width: .38rem;
      height: 1.09rem;
      padding-bottom: .10rem;
    }
  }
  .bottom-super {
    width: 100%;
    height: 5.56rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-bottom-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -.40rem;
    position: relative;
    z-index: 1000;
    padding-top: .67rem;
    .play-number {
      font-size: .14rem;
      font-weight: 600;
      color: #FF5C65;
      line-height: .20rem;
      text-align: center;
    }
    .reword-box {
      width: 3.36rem;
      height: 4.00rem;
      margin: .10rem auto 0;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/lottery-reword-box.png);
      background-size: cover;
      background-repeat: no-repeat;
      padding: .87rem .10rem 0 .90rem;
      .list {
        height: 2.7rem;
        overflow: auto;
        .item {
          display: flex;
          padding-bottom: .25rem;
          &:last-child {
            padding-bottom: 0;
          }
          .pic {
            width: .70rem;
            height: .70rem;
            object-fit: cover;
            border-radius: .08rem;
            margin-right: .24rem;
          }
          .name {
            font-size: .16rem;
            font-weight: 400;
            color: #333333;
            line-height: .22rem;
            padding-top: .07rem;
          }
          .num {
            font-size: .15rem;
            font-weight: 600;
            color: #FF5C65;
            line-height: .21rem;
            padding-top: .13rem;
          }
        }
      }
    }
    .play-person {
      font-size: .14rem;
      font-weight: 600;
      color: #FF5C65;
      line-height: .20rem;
      text-align: center;
      padding-top: .19rem;
    }
  }
  .modal-tip-content {
    width: 2.58rem;
    height: 2.24rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .mask-title {
      font-size: .14rem;
      font-weight: 400;
      color: #404040;
      line-height: .24rem;
      padding-top: 1.11rem;
    }
    .mask-img {
      width: 1.85rem;
      height: .40rem;
      padding-top: .29rem;
    }
  }
  .modal-reword-content {
    width: 100%;
    height: 5.24rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    .mask-img {
      width: .71rem;
      height: .71rem;
      object-fit: cover;
      padding-top: 2.07rem;
    }
    .mask-btn {
      width: 2rem;
      height: .40rem;
      margin-top: .64rem;
      position: absolute;
      top: 2.82rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@-webkit-keyframes rolling {
  to {
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rolling {
  to {
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
