.upload-block {
  width: .50rem;
  height: .50rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  .image {
    width: .50rem;
    height: .50rem;
    object-fit: cover;
    border-radius: 50%;
  }
  .input {
    position: absolute;
    width: .50rem;
    height: .50rem;
    top: 0;
    left: 0;
    opacity: 0;
  }
}