.bank-list-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .44rem 0 0 .28rem;
  .list-super {
    padding-top: .30rem;
    .item {
      display: flex;
      align-items: center;
      font-size: .14rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .30rem;
      .icon {
        width: .26rem;
        height: .26rem;
        margin-right: .10rem;
      }
    }
  }
}
