.bind-card-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: .67rem;
  color: #ffffff;
	.header-tip {
    width: 100%;
    height: .30rem;
    background: #1E1E23;
    padding: 0 .28rem;
    font-size: .12rem;
    font-weight: 400;
    color: #88B6FD;
    line-height: .30rem;
  }
  .bind-card-content {
    padding: .25rem .28rem 0;
    .label {
      font-size: .14rem;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
      padding-bottom: .15rem;
      display: flex;
      align-items: center;
      .icon-name {
        width: .10rem;
        height: .10rem;
        margin-left: .05rem;
      }
    }
    .name {
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .22rem;
      letter-spacing: .02rem;
      padding-bottom: .09rem;
      margin-bottom: .19rem;
      border-bottom: 1px solid #2A2837;
    }
    .adm-input {
      padding-bottom: .09rem;
      margin-bottom: .19rem;
      border-bottom: 1px solid #2A2837;
      .adm-input-element {
        font-size: .16rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: .22rem;
        letter-spacing: .02rem;
        &::placeholder {
          color: #979797;
        }
      }
    }
    .bank-list {
      width: fit-content;
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .22rem;
      margin-top: .49rem;
    }
  }
  .footer {
    width: 100%;
    padding: 2.30rem .28rem .50rem;
    .agree-super {
      padding-bottom: .15rem;
      display: flex;
      align-items: center;
      font-size: .12rem;
      font-weight: 600;
      color: #979797;
      .icon-checkbox {
        width: .12rem;
        height: .12rem;
        margin-right: .10rem;
      }
      .link {
        text-decoration: none;
        color: #88B6FD;
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
  .code-super {
    width: 3.42rem;
    background: #1E1C28;
    border-radius: .06rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .15rem .30rem .20rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .19rem;
      right: .30rem;
    }
    .title {
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .22rem;
      text-align: center;
    }
    .content {
      padding-top: .20rem;
      padding-bottom: .40rem;
      .phone {
        height: .32rem;
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .22rem;
        border-bottom: 1px solid #4B4953;
        padding-bottom: .10rem;
        margin-bottom: .28rem;
      }
      .adm-input {
        padding-bottom: .10rem;
        border-bottom: 1px solid #4B4953;
        .adm-input-element {
          font-size: .16rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .25rem;
          &::placeholder {
            color: #979797;
          }
        }
      }
    }
    .countdown-btn {
      width: .70rem;
      height: .30rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
      background-size: 100% 100%;
      font-size: .10rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .30rem;
      text-align: center;
      position: absolute;
      top: 1.15rem;
      right: .30rem;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
  .name-super {
    width: 3.42rem;
    background: #1E1C28;
    border-radius: .06rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .20rem .12rem;
    .title {
      font-size: .18rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .25rem;
      text-align: center;
    }
    .content {
      padding-bottom: .30rem;
      .desc {
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .22rem;
        padding-top: .20rem;
      }
    }
    .confirm-btn {
      width: 90%;
      margin: 0 auto;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
}
