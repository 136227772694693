.certification-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: 1.14rem .28rem .50rem;
  .container-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .30rem;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .20rem;
    .list-right {
      color: #979797;
      .adm-input-element {
        font-size: .14rem;
        color: #979797;
        text-align: right;
        &::placeholder {
          color: #979797;
        }
      }
    }
  }
  .setting-container-log {
    padding-top: .39rem;
    .confirm-btn {
      width: 100%;
      height: .45rem;
      line-height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
    .btn-tip {
      padding-top: .15rem;
      font-size: .12rem;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: .11rem;
        height: .12rem;
        margin-right: .09rem;
      }
    }
    .desc {
      padding-top: .55rem;
      color: #FFFFFF;
      .title {
        font-size: .16rem;
        font-weight: 600;
        padding-bottom: .20rem;
      }
      .text {
        font-size: .14rem;
        font-weight: 400;
        padding-bottom: .20rem;
      }
    }
  }
}
