.wallet-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding-top: .44rem;
  background-color: #FBFBFB;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/wallet-container-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 -.44rem;
  .wallet-top-super {
    padding: 0 .16rem;
  }
  .wallet-banner-super {
    width: 102%;
    height: 1.68rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/wallet-banner-bg.png);
    background-size: 100% 100%;
    padding-top: .23rem;
    padding-left: .15rem;
    position: relative;
    left: -0.03rem;
    .banner-label {
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
    }
    .banner-value {
      font-size: .32rem;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #333333;
      line-height: .37rem;
      padding-top: .10rem;
    }
    .banner-btn {
      width: .78rem;
      height: .40rem;
      position: absolute;
      top: .98rem;
      right: 0;
    }
  }
  .set-password-super {
    width: 100%;
    height: .54rem;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    border-radius: .06rem;
    padding: 0 .12rem;
    font-size: .14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: .20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .05rem;
    margin-bottom: .10rem;
    .icon-arrow {
      width: .06rem;
      height: .10rem;
      transform: rotate(180deg);
    }
  }
  .wallet-list-super {
    background: #FDFDFD;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    padding: .20rem .16rem;
    min-height: calc(100vh - 2.81rem);
    .list-title {
      padding: 0 .12rem .10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      .list-date {
        font-size: .14rem;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        .icon-arrow {
          width: .11rem;
          height: .06rem;
          margin-left: .05rem;
        }
      }
    }
    .list-tabs {
      padding: 0 .12rem .15rem;
      display: flex;
      .tab-item {
        padding: 0 .16rem;
        height: .28rem;
        line-height: .28rem;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
        border-radius: .04rem;
        font-size: .12rem;
        font-weight: 400;
        color: #333333;
        margin-right: .12rem;
        &.active {
          background-image: linear-gradient(to right, rgba(154, 225, 255, 1) 0%, rgba(118, 140, 252, 1) 100%);
          color: #FFFFFF;
        }
      }
    }
    .list-item {
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      padding: .07rem .12rem;
      margin-bottom: .10rem;
      .info-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: .14rem;
          font-weight: 400;
          color: #333333;
          line-height: .20rem;
          display: flex;
          align-items: center;
          flex: 1;
          .name {
            flex: 1;
            width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .status {
            font-size: .12rem;
            font-weight: 400;
            color: #60C167;
            margin-left: .10rem;
          }
        }
        .pay {
          font-size: .14rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #60C167;
          line-height: .20rem;
          &.plus {
            color: #EF715D;
          }
        }
      }
      .info-bottom {
        display: flex;
        justify-content: space-between;
        font-size: .12rem;
        font-weight: 400;
        color: #666666;
        line-height: .17rem;
        padding-top: .03rem;
      }
    }
    .enpty-super {
      padding-top: .37rem;
      text-align: center;
      .enpty-img {
        width: 2.14rem;
        height: 1.24rem;
        opacity: .5;
      }
      .text {
        padding-top: .41rem;
        font-size: .16rem;
        font-weight: 400;
        color: #666666;
        line-height: .22rem;
      }
    }
  }
  .wallet-tip-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.19rem;
    height: 1.74rem;
    background: #F4F4F4;
    border-radius: .06rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #333333;
    .overlay-content-top {
      text-align: center;
      margin-top: .15rem;
      .top-title {
        font-size: .2rem;
      }
      .top-content {
        padding: 0.10rem;
        font-size: .14rem;
      }
    }
    .overlay-content-bottom {
      height: .5rem;
      border-top: .005rem solid #979797;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .vertical-bar {
        width: .005rem;
        height: .49rem;
        background: #979797;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .left {
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: .2rem;
      }
      .right {
        font-size: .2rem;
        color: #4095E5;
      }
    }
  }
  .wallet-password-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.19rem;
    background: #F4F4F4;
    border-radius: .06rem;
    padding: .20rem 0 .50rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .15rem;
      left: .15rem;
    }
    .title {
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      text-align: center;
    }
    .content {
      font-size: .14rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .20rem;
      padding-top: .19rem;
      text-align: center;
    }
    .input-super {
      user-select: none;
      padding-top: .20rem;
      .password-value-input {
        position: fixed;
        left: 0;
        top: -1000rem;
      }
      .box-list {
        width: 100%;
        height: .38rem;
        padding: 0 .12rem;
        display: flex;
        justify-content: space-between;
        .box-item {
          width: .38rem;
          height: .38rem;
          background: #E6E6E6;
          border-radius: .06rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: .10rem;
            height: .10rem;
            background-color: #333333;
            border-radius: .05rem;
            &.hide {
              display: none;
            }
          }
          .text {
            font-size: .18rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }
    .option-btn {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4095E5;
      line-height: .20rem;
      padding-top: .15rem;
      position: absolute;
      left: 50%;
      bottom: .15rem;
      transform: translateX(-50%);
    }
  }
}

.rv-datetime-picker {
  .rv-picker__toolbar {
    font-size: .14rem;
    border-bottom: 1px solid #DEDEDE;
  }
}
