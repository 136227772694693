.buy-detail-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #FBFBFB;
  padding: .62rem 0 .50rem;
  .status-super {
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    padding: .15rem .28rem;
    &.buy-wait {
      display: flex;
      justify-content: space-between;
    }
    .desc {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: .20rem;
      padding-bottom: .10rem;
    }
    .tip {
      font-size: .12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: .17rem;
    }
    .count-down {
			display: flex;
			align-items: center;
			.label {
				font-size: .14rem;
				color: #333333;
				padding-right: .11rem;
			}
			.colon {
				display: inline-block;
				margin: 0 4px;
				font-size: .12rem;
				color: #333333;
			}
			.block {
				display: inline-block;
				width: .20rem;
				height: .20rem;
				line-height: .20rem;
				text-align: center;
				background: linear-gradient(127deg, #A0F0FF 0%, #5237FA 100%);
				border-radius: .04rem;
				font-size: .12rem;
				color: #FFFFFF;
			}
		}
    .name {
			font-size: .14rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .20rem;
		}
  }
  .content-super {
    padding: .15rem .16rem;
    .banner-super {
      padding: .12rem;
      display: flex;
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      margin-bottom: .15rem;
      .main-pic {
        width: 1.11rem;
        height: 1.11rem;
        object-fit: cover;
        border-radius: .06rem;
      }
      .name-super {
        padding-left: .12rem;
        padding-top: .10rem;
        flex: 1;
        width: 0;
        .name {
          font-size: .18rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: .25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .token {
          padding-top: .15rem;
          font-size: .16rem;
          font-weight: 400;
          color: #333333;
          line-height: .22rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .num {
            color: #88B6FD;
          }
        }
      }
    }
    .info-list {
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      padding: .15rem .12rem;
      .item {
        display: flex;
        justify-content: space-between;
        font-size: .14rem;
        font-weight: 400;
        color: #666666;
        line-height: .20rem;
        padding-bottom: .15rem;
        &:last-child {
          padding-bottom: 0;
        }
        .value {
          color: #333333;
          .icon {
            width: .14rem;
            height: .14rem;
            margin-left: .10rem;
          }
        }
      }
    }
    .pay-super {
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      padding: .15rem .12rem;
      margin-top: .15rem;
      .mode-payment-title {
        font-size: .14rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .20rem;
      }
      .mode-payment-content {
        display: flex;
        align-items: center;
        margin-top: .16rem;
        font-size: .14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: .20rem;
        .mode-payment-icon {
          width: .26rem;
          height: .21rem;
          margin-right: .1rem;
        }
      }
      .add-bankcard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .10rem;
        font-weight: 400;
        color: #979797;
        line-height: .14rem;
        padding-top: .10rem;
        padding-left: .36rem;
        .arrow-icon {
          width: .06rem;
          height: .10rem;
        }
      }
      .bankcard-super {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .10rem;
        font-weight: 400;
        color: #979797;
        line-height: .14rem;
        padding-top: .10rem;
        .block {
          display: flex;
          align-items: center;
          .bank-icon {
            width: .20rem;
            height: .20rem;
            margin-right: .15rem;
          }
          .arrow-icon {
            width: .06rem;
            height: .10rem;
            margin-left: .10rem;
          }
        }
      }
    }
  }
  .footer {
		width: 100%;
		height: .50rem;
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 .16rem;
		font-size: .14rem;
    border-top: 1px solid rgba(151, 151, 151, 0.20);
    .price {
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: .20rem;
      span {
        font-size: .18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .25rem;
        padding-left: .10rem;
      }
    }
		.button {
			width: 1.2rem;
			height: .35rem;
			line-height: .35rem;
			text-align: center;
			background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .14rem;
      color: #FFFFFF;
			font-weight: 600;
		}
	}
  .card-selector {
    width: 100%;
    background: #F2F2F2;
    position: absolute;
    bottom: 0;
    color: #000000;
    padding: .15rem .15rem .39rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .15rem;
      right: .15rem;
    }
    .title {
      font-size: .16rem;
      font-weight: 600;
      line-height: .22rem;
      text-align: center;
    }
    .list-super {
      padding-top: .30rem;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .30rem;
        .card-info {
          font-size: .10rem;
          font-weight: 500;
          line-height: .14rem;
          display: flex;
          align-items: center;
          .bank-icon {
            width: .20rem;
            height: .20rem;
            margin-right: .13rem;
          }
        }
        .choose-icon {
          width: .14rem;
          height: .11rem;
        }
      }
    }
    .add-super {
      font-size: .10rem;
      font-weight: 500;
      line-height: .14rem;
      display: flex;
      align-items: center;
      .add-icon {
        width: .20rem;
        height: .20rem;
        margin-right: .13rem;
      }
    }
  }
  .code-super {
    width: 3.42rem;
    background: #F4F4F4;
    border-radius: .06rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .15rem .30rem .20rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .19rem;
      right: .30rem;
    }
    .title {
      font-size: .16rem;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      text-align: center;
    }
    .content {
      padding-top: .20rem;
      padding-bottom: .40rem;
      .phone {
        height: .32rem;
        font-size: .16rem;
        font-weight: 400;
        color: #333333;
        line-height: .22rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        padding-bottom: .10rem;
        margin-bottom: .28rem;
      }
      .adm-input {
        padding-bottom: .10rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        .adm-input-element {
          font-size: .16rem;
          font-weight: 400;
          color: #333333;
          line-height: .25rem;
          &::placeholder {
            color: #999999;
          }
        }
      }
    }
    .countdown-btn {
      width: .70rem;
      height: .30rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .10rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .30rem;
      text-align: center;
      position: absolute;
      top: 1.15rem;
      right: .30rem;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
        opacity: .6;
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
        opacity: .6;
      }
    }
  }
}
