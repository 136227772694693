.creator-describe-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .25rem 0 .16rem;

  .item {
    padding-bottom: .20rem;

    .label-super {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .10rem;

      .require-star {
        color: #FF2300;
      }
    }
  }

  .adm-text-area {
    width: 100%;
    height: 2.00rem;
    background: #1E1E23;
    border-radius: .06rem;
    padding: .15rem .12rem .27rem .12rem;

    .adm-text-area-element {
      color: #FFFFFF;
      font-size: .12rem;

      &::placeholder {
        color: #979797;
      }
    }

    .adm-text-area-count {
      margin-top: -.06rem;
      font-size: .12rem;
    }
  }

  .submit-super {
    width: 100%;
    background-color: #100D20;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: .20rem 0 .34rem;
    .submit-btn {
      width: 3.19rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
    }
  }

  // 内容长度需要自适应
  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.19rem;
    height: auto;
    padding-bottom: .5rem;
    background: #4A4A4A;
    border-radius: .06rem;
    color: #FFFFFF;

    .overlay-content-top {
      text-align: center;
      margin-top: .15rem;

      .top-title {
        font-size: .2rem;
      }

      .top-content {
        padding: 0.10rem;
        font-size: .14rem;

        .describe-mask-error {
          color: #FF2300;
          word-wrap: break-word;
        }
      }
    }

    .overlay-content-bottom {
      height: .5rem;
      border-top: .005rem solid #979797;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .vertical-bar {
        width: .005rem;
        height: .49rem;
        background: #979797;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .left {
        font-size: .2rem;
      }

      .right {
        font-size: .2rem;
        color: #88B6FD;
      }
    }
  }

}