.collection-shining-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .66rem .28rem 0;
  .top-super {
    width: 100%;
    display: flex;
    align-items: center;
    height: .44rem;
    padding-left: .16rem;
    position: fixed;
    top: 0;
    left: 0;
    .icon-close {
      width: .14rem;
      height: .14rem;
    }
  }
  .poster-super {
    width: 100%;
    height: 4.48rem;
    .poster-img {
      width: 100%;
      height: 4.48rem;
    }
  }
  .poster-tip {
    font-size: .14rem;
    font-weight: 600;
    color: #999999;
    line-height: .20rem;
    text-align: center;
    padding: .40rem 0 .50rem;
  }
  .invite-canvas {
    position: absolute;
    top: -99999rem;
    left: -99999rem;
  }
}