.hash-container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	color: #ffffff;
	padding: 1.14rem .28rem;
	.search-title {
    font-size: .16rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .22rem;
	  text-align: center;
    padding-bottom: .10rem;
  }
  .search-tip {
    font-size: .12rem;
    font-weight: 400;
    color: #979797;
    line-height: .17rem;
	  text-align: center;
    padding-bottom: .30rem;
  }
  .adm-text-area {
    width: 100%;
    height: 1.74rem;
    background: #4A4A4A;
    border-radius: .06rem;
    padding: .15rem;
    .adm-text-area-element {
      color: #FFFFFF;
      font-size: .14rem;
      &::placeholder {
        color: #979797;
      }
    }
  }
  .confirm-btn {
    margin-top: .30rem;
    width: 100%;
    height: .45rem;
    line-height: .45rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
    background-size: 100% 100%;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    &.enable {
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    }
  }
}
