
.home-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #FFFFFF;
  .nav-super {
    width: 100%;
    height: .44rem;
    background-color: #100D20;
    padding: 0 .16rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: .20rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .44rem;
  }
  .home-content {
    padding: .58rem .16rem .74rem;
    .entrance-super {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: .20rem;
      width: 100%;
      padding: .27rem 0 .20rem;
      border-radius: .06rem;
      .entrance-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: .12rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .17rem;
        .icon {
          width: .48rem;
          height: .48rem;
          margin-bottom: .02rem;
        }
      }
    }
    .tabs-super {
      height: .44rem;
      padding-bottom: .14rem;
      background-color: #100D20;
      &.top-fixed {
        width: 100%;
        position: fixed;
        top: .44rem;
        left: 0;
        z-index: 1;
        padding: 0 .16rem;
      }
      .adm-tabs-header {
        border-bottom: 0;
        .adm-tabs-header-mask {
          display: none;
        }
      }
      .adm-tabs-tab-line {
        display: none;
      }
      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }
      .adm-tabs-tab {
        font-size: .16rem;
        font-weight: 400;
        color: #979797;
        margin: 0;
        &.adm-tabs-tab-active {
          font-size: .18rem;
          color: #FFFFFF;
          font-weight: 600;
        }
      }
      .adm-tabs-tab-wrapper {
        padding-left: 0;
        padding-right: .20rem;
      }
    }
    .tabs-block {
      width: 100%;
      height: .44rem;
    }
    .swiper-super {
      width: 100%;
      height: 1.40rem;
      padding-bottom: .18rem;
      box-sizing: content-box;
      .swiper-img {
        width: 100%;
        height: 1.40rem;
        object-fit: cover;
        border-radius: .06rem;
      }
      .adm-swiper-indicator {
        bottom: .26rem;
        right: .12rem;
        left: initial;
        transform: translateX(0);
        .adm-page-indicator-dot {
          width: .05rem;
          height: .05rem;
          background-color: #FFFFFF;
          border-radius: .04rem;
          opacity: .5;
          &.adm-page-indicator-dot-active {
            opacity: 1;
          }
        }
      }
    }
    .enpty-super {
      padding-top: 1.23rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
      .enpty-img {
        width: 2.01rem;
        height: 1.15rem;
        margin-bottom: .52rem;
      }
    }
  }
  .tip-super {
    background: #1E1E23;
    border-radius: .06rem;
    width: 100%;
    height: .50rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .16rem;
    font-size: .14rem;
    font-weight: 400;
    color: #FFFFFF;
    position: fixed;
    bottom: .50rem;
    .btn-super {
      display: flex;
      align-items: center;
      .btn {
        width: .64rem;
        height: .30rem;
        line-height: .30rem;
        margin-right: .12rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/small-btn-enable.png);
        background-size: 100% 100%;
        text-align: center;
      }
      .icon-close {
        width: .14rem;
        height: .14rem;
      }
    }
  }
  .product-list {
    .product-item {
      background: #1E1E23;
      border-radius: .06rem;
      margin-bottom: .15rem;
      position: relative;
      .main-img {
        width: 100%;
        border-radius: .06rem;
      }
      .topic-img {
        width: 100%;
        aspect-ratio: 1.80;
        object-fit: cover;
        border-radius: .06rem;
      }
      .content-super {
        padding: .10rem .12rem .12rem;
        position: relative;
        .title {
          display: flex;
          align-items: center;
          font-size: .16rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .22rem;
          padding-bottom: .10rem;
          .category-img {
            width: .14rem;
            height: .14rem;
            margin-left: .05rem;
          }
        }
        .topic-title {
          font-size: .16rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .22rem;
          padding-bottom: .10rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content {
          font-size: .14rem;
          font-weight: 400;
          color: #979797;
          line-height: .24rem;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tag-super {
          display: flex;
          font-size: .11rem;
          padding-bottom: .10rem;
          .limit-super {
            display: flex;
            margin-right: .05rem;
            .limit-label {
              background: linear-gradient(127deg, #A0F0FF 0%, #5237FA 100%);
              border-radius: 1px 0px 0px 1px;
              height: .16rem;
              line-height: .16rem;
              padding: 0 .05rem;
              font-weight: 600;
              color: #06213B;
            }
            .limit-num {
              background: #3B3348;
              border-radius: 0 1px 1px 0;
              height: .16rem;
              line-height: .16rem;
              padding: 0 .06rem;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .tag-1 {
            background: linear-gradient(139deg, #A0F0FF 0%, #5237FA 100%);
            border-radius: 1px;
            height: .16rem;
            line-height: .16rem;
            padding: 0 .06rem;
            font-weight: 600;
            color: #06213B;
            margin-right: .05rem;
          }
          .tag-2 {
            background: #3B3348;
            border-radius: 1px;
            height: .16rem;
            line-height: .16rem;
            padding: 0 .06rem;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
        .creator-super {
          display: flex;
          align-items: center;
          font-size: .12rem;
          font-weight: 400;
          color: #999999;
          .avatar {
            width: .15rem;
            height: .15rem;
            border-radius: 50%;
            margin-right: .08rem;
          }
        }
        .price {
          position: absolute;
          bottom: .10rem;
          right: .12rem;
          font-size: .20rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .28rem;
        }
      }
      .sell-status-super {
        position: absolute;
        top: .10rem;
        left: .12rem;
        height: .20rem;
        line-height: .20rem;
        background: #100D20;
        border-radius: .10rem;
        padding: 0 .09rem;
        font-size: .12rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        &.sell-fire {
          color: #EF715D;
        }
        &.sell-time {
          color: #60C167;
        }
        &.sell-enpty {
          color: #969696;
        }
        .fire-icon {
          width: .10rem;
          height: .12rem;
          margin-right: .05rem;
        }
        .time-icon {
          width: .12rem;
          height: .12rem;
          margin-right: .05rem;
        }
      }
    }
  }
}

.media-modal-super {
  .media-player {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.19rem;
  height: 1.74rem;
  background: #4A4A4A;
  border-radius: .06rem;
  color: #FFFFFF;
  .overlay-content-top {
    text-align: center;
    margin-top: .15rem;
    .top-title {
      font-size: .2rem;
    }
    .top-content {
      padding: 0.10rem;
      font-size: .14rem;
    }
    .top-add {
      width: 1.91rem;
      height: .25rem;
      background: #646464;
      border-radius: .02rem;
      opacity: 0.69;
      border: 0;
      color: #FFFFFF;
      font-weight: 600;
      text-align: center;
      &::placeholder {
        color:#999999;
        font-size: .12rem;
      }
    }
  }
  .overlay-content-center {
    text-align: center;
    margin-top: .50rem;
    .top-title {
      font-size: .2rem;
    }
  }
  .overlay-content-bottom {
    height: .5rem;
    border-top: .005rem solid #979797;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .vertical-bar {
      width: .005rem;
      height: .49rem;
      background: #979797;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .left {
      font-size: .2rem;
    }
    .right {
      font-size: .2rem;
      color: #88B6FD;
    }
  }
}
