.tab-bar-container {
  width: 100%;
  height: .50rem;
  background-color: #100D20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .38rem;
  position: fixed;
  bottom: 0;
  left: 0;
  .tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .09rem;
    font-weight: 400;
    color: #6E6B7C;
    line-height: .13rem;
    .icon-img {
      width: .24rem;
      height: .24rem;
      margin-bottom: .03rem;
    }
    &.current {
      color: #88B6FD;
    }
  }
}
