.search-collection-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #FBFBFB;
  padding: .90rem 0 .50rem;
  .banner-super {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #FBFBFB;
    padding-top: .62rem;
  }
  .form-super {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 .16rem;
    .adm-input {
      width: 3rem;
      height: .30rem;
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      padding-left: .41rem;
      .adm-input-element {
        font-size: .12rem;
        font-weight: 400;
        color: #333333;
        &::placeholder {
          color: #DEDEDE;
        }
      }
      .adm-input-clear {
        padding-right: .20rem;
      }
    }
    .icon-search {
      width: .14rem;
      height: .14rem;
      position: absolute;
      left: .28rem;
      top: .09rem;
    }
    .search-btn {
      font-size: .16rem;
      font-weight: 400;
      color: #333333;
      line-height: .30rem;
    }
  }
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
      opacity: .5;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #666666;
      line-height: .22rem;
    }
  }
  .collection-list {
    padding-top: .15rem;
    .item {
      padding-bottom: .21rem;
      &:last-child {
        padding-bottom: 0;
      }
      .content-super {
        padding: 0 .16rem;
      }
      .info-super {
        padding: .12rem;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
        border-radius: .06rem;
        .left {
          display: flex;
          align-items: center;
          font-size: .14rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: .20rem;
          flex: 1;
          width: 0;
          .main-img {
            width: .50rem;
            height: .50rem;
            border-radius: .06rem;
            margin-right: .10rem;
            object-fit: cover;
          }
          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .right {
          display: flex;
          font-size: .12rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .17rem;
          padding-top: .04rem;
          .account {
            text-align: center;
            padding-left: .19rem;
            .number {
              color: #4095E5;
            }
            .text {
              padding-top: .05rem;
            }
          }
        }
      }
      .token-super {
        padding: .12rem 0 .18rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        .tip {
          font-size: .14rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .20rem;
        }
        .token-list {
          padding-top: .10rem;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          column-gap: .10rem;
          row-gap: .10rem;
          .token-item {
            width: .78rem;
            height: .26rem;
            line-height: .26rem;
            background: #FFFFFF;
            box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
            border-radius: .04rem;
            font-size: .12rem;
            font-weight: 400;
            color: #4095E5;
            text-align: center;
            &.disable {
              background: #999999;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}
