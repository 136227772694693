.collection-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: .63rem .16rem .50rem;
  .entrance-publish {
    position: fixed;
    right: .10rem;
    bottom: 1.07rem;
    width: .68rem;
    height: .68rem;
    z-index: 100;
  }
  .tabs-super {
    width: 100%;
    height: .44rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #100D20;
    .adm-tabs {
      width: 100%;
    }
    .adm-tabs-header {
      border-bottom: 0;
    }
    .adm-tabs-tab-line {
      display: none;
    }
    .adm-tabs-tab {
      width: 1.17rem;
      text-align: center;
      font-size: .16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding: .08rem 0;
      color: #FFFFFF;
      &.adm-tabs-tab-active {
        color: #88B6FD;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/sale-order-tab-line.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% 1px;
      }
      .title-super {
        display: flex;
        align-items: center;
        justify-content: center;
        .count {
          font-size: .14rem;
          margin-left: .05rem;
        }
      }
    }
  }
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
    }
    .link {
      font-size: .16rem;
      font-weight: 400;
      color: #88B6FD;
      line-height: .22rem;
      text-decoration: none;
      padding-top: .10rem;
    }
  }
  .content-super-con {
    position: relative;
    min-height: calc(100vh - .63rem);
    .content-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: transparent;
    }
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .10rem;
    .product-item {
      background: #1E1E23;
      border-radius: .06rem;
      position: relative;
      min-width: 0;
      .mask-super {
        width: 100%;
        min-height: 1.66rem;
        aspect-ratio: 1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: .06rem;
        background-color: rgba(0, 0, 0, .84);
        font-size: .14rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon-sending {
          width: .46rem;
          height: .50rem;
          margin-bottom: .10rem;
        }
        .icon-providing {
          width: .60rem;
          height: .50rem;
          margin-bottom: .10rem;
        }
      }
      .main-img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: .06rem;
      }
      .content-super {
        padding: .10rem .12rem .12rem;
        position: relative;
        .title {
          font-size: .14rem;
          font-weight: 400;
          color: #FFFFFF;
          padding-bottom: .05rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .hash {
          font-size: .12rem;
          font-weight: 400;
          color: #88B6FD;
          padding-bottom: .15rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .creator {
          display: flex;
          justify-content: space-between;
          font-size: .14rem;
          font-weight: 400;
          color: #FFFFFF;
          .category-img {
            width: .15rem;
            height: .15rem;
          }
        }
      }
    }
  }
  .create-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: .10rem;
    position: relative;
    .list {
      min-width: 0;
      .item {
        width: 100%;
        background: #1E1E23;
        border-radius: .06rem;
        margin-bottom: .10rem;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .item-main-img {
          position: relative;

          .main-img {
            width: 100%;
            border-radius: .06rem;
            vertical-align: bottom;
          }

          .icon-option {
            position: absolute;
            bottom: .12rem;
            right: .12rem;
            width: .25rem;
            height: .05rem;
          }

          .main-btn {
            width: .74rem;
            height: .28rem;
            background-color: #333;
            font-size: .14rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            position: absolute;
            right: 0;
            bottom: -.28rem;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;

            .icon {
              width: .16rem;
              height: .16rem;
              margin-right: .05rem;
            }
          }
        }

        .info-super {
          padding: .10rem .12rem;

          .name {
            font-size: .14rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .20rem;
            padding-bottom: .10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .data-super {
            display: flex;
            justify-content: space-between;
            font-size: .14rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .20rem;

            .hot-super {
              display: flex;
              align-items: center;

              .icon {
                width: .15rem;
                height: .15rem;
              }
            }
          }
        }

        .sell-status-super {
          position: absolute;
          top: .0rem;
          left: .0rem;
          height: .25rem;
          width: .68rem;
          line-height: .25rem;
          color: #FFFFFF;
          border-top-left-radius: .06rem;
          border-bottom-right-radius: .06rem;
          font-size: .10rem;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;

          &.one {
            background: #EF715E;
          }

          &.three {
            background: #333333;
          }

          &.two-audit {
            background: linear-gradient(139deg, #9DE9FF 0%, #7488FC 100%);
          }

          &.two-finish {
            background: #999999;
          }

          &.two-stop {
            background: #666666;
          }
        }
      }
    }
  }
}
  