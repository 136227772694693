html, body {
  padding: 0;
  margin: 0;
  font-family: PingFangSC-Regular, PingFang SC;
  background-color: #100D20;
}

ul, li { 
  padding: 0;
  margin: 0;
  list-style: none;
}

div, ul, li {
  box-sizing: border-box;
}

.tcaptcha-transform {
  transform: scale(0.8);
}

.t-verify {
  transform: scale(0.5);
}
