.order-sell-done-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: .62rem 0 .50rem;
  .status-super {
    width: 100%;
    height: .77rem;
    background: #1E1E23;
    padding: 0 .28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .wait {
      font-size: .16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .22rem;
    }
    .desc {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .10rem;
    }
    .tip {
      font-size: .12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: .17rem;
    }
  }
  .content-super {
    padding: .15rem .16rem;
    .banner-super {
      padding: .12rem;
      display: flex;
      background: #1E1E23;
      border-radius: .06rem;
      margin-bottom: .15rem;
      .main-pic {
        width: 1.11rem;
        height: 1.11rem;
        object-fit: cover;
        border-radius: .06rem;
      }
      .name-super {
        padding-left: .12rem;
        padding-top: .10rem;
        flex: 1;
        width: 0;
        .name {
          font-size: .18rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .token {
          padding-top: .15rem;
          font-size: .16rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .22rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .num {
            color: #88B6FD;
          }
        }
      }
    }
    .calc-super {
      background: #1E1E23;
      border-radius: .06rem;
      margin-bottom: .15rem;
      padding-top: .06rem;
      .fee-list {
        padding: .09rem .12rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        .item {
          display: flex;
          justify-content: space-between;
          font-size: .14rem;
          font-weight: 400;
          color: #979797;
          line-height: .20rem;
          padding-bottom: .15rem;
          &:last-child {
            padding-bottom: 0;
          }
          .value {
            color: #FFFFFF;
            &.blod {
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            .mark {
              font-size: .10rem;
            }
          }
        }
      }
      .income-super {
        padding: .10rem .12rem .15rem;
        display: flex;
        justify-content: space-between;
        font-size: .16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .22rem;
        .label {
          color: #979797;
        }
      }
    }
    .info-list {
      background: #1E1E23;
      border-radius: .06rem;
      padding: .15rem .12rem;
      .item {
        display: flex;
        justify-content: space-between;
        font-size: .14rem;
        font-weight: 400;
        color: #979797;
        line-height: .20rem;
        padding-bottom: .15rem;
        &:last-child {
          padding-bottom: 0;
        }
        .value {
          color: #FFFFFF;
          .icon {
            width: .14rem;
            height: .14rem;
            margin-left: .10rem;
          }
        }
      }
    }
  }
}
