.foundry-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
	color: #ffffff;
  padding-top: 0.44rem;
  .foundry-top-super {
    padding: 0 0.16rem;
  }
  .foundry-banner-super {
    width: 3.54rem;
    height: 1.71rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/foundry-background.png);
    background-size: 100% 100%;
    position: relative;
    left: -0.09rem;
		text-align: center;
		padding-top: .54rem;
    .banner-label {
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.22rem;
    }
    .banner-value {
      color: #ffffff;
      line-height: 0.17rem;
      padding-top: 0.15rem;
			.banner-value-one {
				font-size: 0.32rem;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
			}
			.banner-value-two {
				font-size: 0.12rem;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
			}
    }
  }
  .foundry-list-super {
    padding: 0.2rem 0.16rem;
    .list-title {
			margin-bottom: .15rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: .22rem;
    }
    .list-content {
      height: calc(100vh - 2.72rem);
      overflow: auto;
      .list-item {
        width: 3.43rem;
        height: .54rem;
        background: #1E1E23;
        border-radius: 0.06rem;
        padding: 0.07rem 0.12rem;
        margin-bottom: 0.1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info-left {
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          flex: 1;
          width: 0;
          .title {
            font-size: 0.14rem;
            color: #FFFFFF;
            line-height: 0.2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .time {
            font-size: 0.12rem;
            color: #979797;
            line-height: 0.17rem;
          }
        }
        .info-right {
          font-size: 0.14rem;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #FF2300;
          line-height: 0.20rem;
          &.green {
            color: #60C167;
          }
        }
      }
      .enpty-super {
        padding-top: 1.61rem;
        text-align: center;
        .enpty-img {
          width: 2.14rem;
          height: 1.24rem;
        }
        .text {
          padding-top: .41rem;
          font-size: .16rem;
          font-weight: 400;
          color: #6E6B7C;
          line-height: .22rem;
        }
      }
    }
  }
}
