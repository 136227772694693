.message-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .77rem 0.16rem 0;
	.read-all {
		position: fixed;
		top: .14rem;
		right: .16rem;
		font-size: .12rem;
		z-index: 1001;
	}
	.enpty-super {
		padding-top: 1.61rem;
		text-align: center;
		.enpty-img {
			width: 2.14rem;
			height: 1.24rem;
		}
		.text {
			padding-top: .41rem;
			font-size: .16rem;
			font-weight: 400;
			color: #6E6B7C;
			line-height: .22rem;
		}
	}
	.message-container-content {
		width: 100%;
		height: 2.28rem;
		margin-bottom: .40rem;
		&:last-child {
			margin-bottom: 0;
		}
		.content-time {
			font-size: .12rem;
			color: #979797;
			text-align: center;
		}
		.content-box {
			width: 3.43rem;
			height: 1.96rem;
			background: #1E1C28;
			border-radius: .06rem;
			margin-top: .16rem;
			padding: .15rem .12rem;
			.content-box-title {
				font-size: .16rem;
				font-weight: 600;
				.content-box-img {
					width: .06rem;
					height: .06rem;
					margin-left: .08rem;
					margin-bottom: .02rem;
				}
			}
			.content-box-center {
				height: 1.22rem;
				display: flex;
				align-items: center;
				.content-box-img {
					width: .92rem;
					height: .92rem;
					object-fit:cover;
					border-radius: .06rem;
					
				}
				.content-box-describe {
					margin-left: .15rem;
					.describe-title {
						margin-bottom: .1rem;
						font-size: .16rem;
						width: 1.8rem;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.describe-content {
						font-size: .14rem;
						color: #979797;
						width: 1.8rem;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
			}
			.content-box-bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.title-left {
					font-size: .16rem;
					font-weight: 600;
				}
				.icon-arrow {
					width: .09rem;
					height: .15rem;
					transform: rotate(180deg);
				}
			}
		}
	}
}
