.password-set-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 1.18rem;
  .set-title {
    font-size: .20rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .28rem;
    text-align: center;
    padding-bottom: .87rem;
  }
  .content {
    user-select: none;
    .password-value-input {
      position: fixed;
      left: 0;
      top: -1000rem;
    }
    .box-list {
      width: 100%;
      height: .46rem;
      padding: 0 .28rem;
      display: flex;
      justify-content: space-between;
      .box-item {
        width: .46rem;
        height: 100%;
        background: #1E1E23;
        border-radius: .06rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
          width: .12rem;
          height: .12rem;
          background-color: #FFFFFF;
          border-radius: .06rem;
          &.hide {
            display: none;
          }
        }
      }
    }
  }
}
