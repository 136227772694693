.pay-status-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding: 0 .40rem;
  .icon-pay-success {
    width: .70rem;
    height: .51rem;
    padding-top: 1.5rem;
  }
  .icon-pay-fail {
    width: .46rem;
    height: .46rem;
    padding-top: 1.55rem;
  }
  .icon-pay-pending {
    width: .50rem;
    height: .50rem;
    padding-top: 1.55rem;
  }
  .title {
    font-size: .20rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .28rem;
    padding-top: .30rem;
  }
  .subtitle {
    font-size: .14rem;
    font-weight: 600;
    color: #FFFFFF;
    padding-top: .20rem;
  }
  .button-super {
    padding: .40rem .22rem 0;
    display: flex;
    justify-content: space-between;
    .button {
			width: 1.2rem;
			height: .35rem;
			line-height: .35rem;
			text-align: center;
			background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .14rem;
			font-weight: 600;
      color: #FFFFFF;
			&.cancel {
				background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png)
			}
		}
  }
  .compose-success-modal {
    width: 3.43rem;
    border-radius: .06rem;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .top-tip {
      font-size: .18rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .25rem;
    }
    .award-name {
      font-size: .20rem;
      font-weight: 600;
      color: #E2AF1C;
      line-height: .28rem;
      padding-top: .15rem;
    }
    .bottom-tip {
      font-size: .16rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .22rem;
      padding-top: .20rem;
    }
    .award-img {
      width: 1.60rem;
      height: 1.60rem;
      border-radius: .06rem;
      margin-top: .60rem;
    }
    .continue-btn {
      width: 2.82rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin-top: .50rem;
      position: relative;
      z-index: 2;
    }
    .icon-close {
      width: .20rem;
      height: .20rem;
      margin-top: .15rem;
      margin-bottom: .09rem;
      position: relative;
      z-index: 2;
    }
    .star-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .open-box-modal {
    width: 3.43rem;
    height: 3.88rem;
    border-radius: .06rem;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .top-tip {
      font-size: .20rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
      span {
        color: #E2AF1C;
      }
    }
    .bottom-tip {
      font-size: .18rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .25rem;
      padding-top: .15rem;
    }
    .animation {
      width: 2.86rem;
      height: 5.00rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}