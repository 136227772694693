.show-container {
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/collection-show-light.png);
  background-position: center top;
  position: relative;
  .header-super {
    width: 100%;
    position: absolute;
    z-index: 2;
    padding: .44rem .28rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      color: #FFFFFF;
      .name {
        font-size: .20rem;
        font-weight: 600;
        line-height: .28rem;
        padding-bottom: .10rem;
      }
      .creator {
        font-size: .14rem;
        font-weight: 400;
        line-height: .20rem;
      }
    }
    .icon-close {
      width: .14rem;
      height: .14rem;
    }
  }
  .product-canvas {
    position: relative;
    z-index: 1;
  }
  .product-bottom {
    width: 3.28rem;
    height: .97rem;
    position: absolute;
    bottom: .80rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .btn-group {
    position: absolute;
    bottom: 1.20rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 2rem;
    height: .44rem;
    background: #100D20;
    border-radius: .25rem;
    padding: 0 .20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      margin-right: .30rem;
      width: .20rem;
      height: .20rem;
      &.play {
        width: .16rem;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .spinner {
    width: .80rem;
    height: .80rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -.40rem;
    margin-top: -.40rem;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
