.announce-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: 1.07rem .16rem 0;

  .search-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: .62rem .16rem .10rem;
    background-color: #100D20;

    .form-super {
      display: flex;
      justify-content: space-between;
      position: relative;

      .adm-input {
        width: 3rem;
        height: .30rem;
        background: #1E1E23;
        border-radius: .06rem;
        padding-left: .41rem;

        .adm-input-element {
          font-size: .12rem;
          font-weight: 400;
          color: #FFFFFF;

          &::placeholder {
            color: #979797;
          }
        }
      }

      .icon-search {
        width: .14rem;
        height: .14rem;
        position: absolute;
        left: .12rem;
        top: .09rem;
      }

      .search-btn {
        font-size: .16rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .30rem;
      }
    }
  }

  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
    }
  }

  .announce-list {

    .list-content {
      margin-bottom: .15rem;

      &:last-child {
        margin-bottom: 0;
      }

      .announce-time {
        text-align: center;
        color: #979797;
        font-size: .12rem;
        margin-bottom: .15rem;
      }

      .item {
        background: #1E1C28;
        border-radius: .06rem;

        .main-img {
          width: 100%;
          height: 1.31rem;
          object-fit: cover;
          border-radius: .06rem;
        }

        .main-bottom {
          padding: .10rem .12rem;

          .title {
            font-size: .14rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .20rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .desc {
            font-size: .12rem;
            font-weight: 400;
            color: #979797;
            line-height: .17rem;
            margin-top: 0.10rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }
}