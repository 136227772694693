.block-support-container {
  text-align: center;
  .block-support-img {
    width: 2.29rem;
    height: .29rem;
  }
  .block-support-text {
    font-size: .12rem;
    font-weight: 400;
    color: #979797;
    line-height: .17rem;
    padding-top: .03rem;
    text-align: center;
  }
}
