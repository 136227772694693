.product-progress-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #FFFFFF;
  padding: .80rem .16rem 0;
  .progress-banner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 1.12rem;
    padding: 0 .12rem .15rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-banner-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: .15rem;
    .info-super {
      display: flex;
      flex: 1;
      .main-pic {
        position: relative;
        top: -.15rem;
        width: 1.12rem;
        height: 1.12rem;
        border-radius: .06rem;
        object-fit: cover;
        margin-right: .17rem;
      }
      .title-super {
        flex: 1;
        width: 0;
        .title {
          font-size: .14rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .20rem;
          padding: .23rem 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          width: 1.02rem;
          height: .22rem;
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
          background-size: 100% 100%;
          font-size: .11rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .22rem;
          text-align: center;
        }
      }
    }
    .process-super {
      width: .52rem;
      height: .55rem;
      padding: .16rem .05rem .15rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-process-bg.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: .12rem;
      font-weight: 400;
      color: #FFFFFF;
      .outer {
        width: 100%;
        height: .06rem;
        background: #FFFFFF;
        border-radius: .03rem;
        .inner {
          height: .06rem;
          background: #778FFC;
          border-radius: .03rem;
        }
      }
    }
  }
  .progress-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: .22rem;
    row-gap: .24rem;
    padding: .24rem .12rem .15rem .22rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-content-bg.png);
    background-size: 100% 4.26rem;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #1E1E23;
    border-radius: .06rem;
    .product-item {
      width: 100%;
      background: #1E1E23;
      border-radius: .06rem;
      position: relative;
      &.disable {
        filter: grayscale(100%);
      }
      .pic-super {
        width: 100%;
        aspect-ratio: 1;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-collection-bg.png);
        background-size: 100% 100%;
        padding: 1px;
        margin-bottom: .15rem;
        position: relative;
        .main-pic {
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: .06rem;
          vertical-align: middle;
          position: relative;
          top: -2px;
          left: -2px;
        }
        .hold-tip {
          width: .52rem;
          height: .20rem;
          line-height: .20rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: .10rem;
          font-weight: 600;
          color: #FFFFFF;
          text-align: center;
          background-image: linear-gradient(to right, rgba(105, 105, 105, 0), rgba(105, 105, 105, 1), rgba(105, 105, 105, 0));
        }
      }
      .name {
        width: .81rem;
        height: .21rem;
        padding: 0 .08rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-collection-name-bg.png);
        background-size: 100% 100%;
        font-size: .12rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .21rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
      }
      .level {
        position: absolute;
        top: -.09rem;
        left: -.12rem;
        width: .25rem;
        height: .19rem;
      }
      .hold-num {
        width: .44rem;
        height: .20rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/progress-collection-number-bg.png);
        background-size: 100% 100%;
        font-size: .12rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .20rem;
        text-align: center;
        position: absolute;
        top: -.12rem;
        right: -.03rem;
      }
    }
  }
}
  