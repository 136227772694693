.sell-wait-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #FBFBFB;
  padding: .62rem 0.16rem 1.20rem;
  .banner-super {
    padding: .12rem;
    display: flex;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    border-radius: .06rem;
    margin-bottom: .15rem;
    .main-pic {
      width: 1.11rem;
      height: 1.11rem;
      object-fit: cover;
      border-radius: .06rem;
    }
    .name-super {
      padding-left: .12rem;
      padding-top: .10rem;
      .name {
        font-size: .18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .25rem;
      }
      .token {
        padding-top: .15rem;
        font-size: .16rem;
        font-weight: 400;
        color: #333333;
        line-height: .22rem;
        .num {
          color: #88B6FD;
        }
      }
    }
  }
  .form-super {
    padding: .15rem .12rem;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    border-radius: .06rem;
    margin-bottom: .15rem;
    .title {
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
    }
    .price-super {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: .05rem;
      .origin-price {
        font-size: .14rem;
        font-weight: 400;
        color: #666666;
        line-height: .20rem;
      }
      .input-super {
        width: 1.50rem;
        padding-bottom: .04rem;
        padding-left: .10rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.20);
        display: flex;
        align-items: center;
        .yuan {
          font-size: .16rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: .22rem;
          padding-right: .10rem;
        }
        .price-value-input {
          border: none;
          background: transparent;
          font-size: .24rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: .33rem;
          width: 100%;
          &::placeholder {
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .calc-super {
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
    border-radius: .06rem;
    margin-bottom: .30rem;
    .fee-list {
      padding: 0 .12rem .09rem;
      border-bottom: 1px solid rgba(151, 151, 151, 0.20);
      .item {
        display: flex;
        justify-content: space-between;
        font-size: .14rem;
        font-weight: 400;
        color: #666666;
        line-height: .20rem;
        padding-top: .15rem;
        .value {
          color: #333333;
        }
      }
    }
    .income-super {
      padding: .12rem;
      display: flex;
      justify-content: space-between;
      .label {
        display: flex;
        align-items: center;
        font-size: .16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .22rem;
        .icon {
          width: .14rem;
          height: .14rem;
          margin-left: .05rem;
        }
      }
      .value {
        font-size: .20rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .28rem;
        .yuan {
          padding-right: .10rem;
        }
      }
    }
  }
  .code-btn {
    width: 3.19rem;
    height: .45rem;
    line-height: .45rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    background-size: 100% 100%;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin: 0 auto;
    &.off {
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      margin-top: .15rem;
      opacity: .6;
    }
    &.disable {
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      margin-top: .15rem;
      opacity: .9;
    }
  }
  .income-tip-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.19rem;
    background: #F4F4F4;
    border-radius: .06rem;
    padding: .20rem .26rem;
    text-align: center;
    .icon-close {
      position: absolute;
      top: .15rem;
      left: .15rem;
      width: .14rem;
      height: .14rem;
    }
    .title {
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      padding-bottom: .19rem;
    }
    .content {
      font-size: .14rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: .20rem;
      padding-bottom: .20rem;
    }
    .btn {
      width: 1.60rem;
      height: .35rem;
      line-height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      margin: 0 auto;
    }
  }
  .input-password-super {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.19rem;
    background: #F4F4F4;
    border-radius: .06rem;
    padding: .20rem 0 .50rem;
    text-align: center;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .15rem;
      left: .15rem;
    }
    .content {
      user-select: none;
      .title {
        font-size: .16rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .22rem;
        padding-bottom: .19rem;
      }
      .subtitle {
        font-size: .14rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: .20rem;
        padding-bottom: .20rem;
      }
      .password-value-input {
        position: fixed;
        left: 0;
        top: -1000rem;
      }
      .box-list {
        width: 100%;
        height: .38rem;
        padding: 0 .12rem;
        display: flex;
        justify-content: space-between;
        .box-item {
          width: .38rem;
          height: .38rem;
          background: #E6E6E6;
          border-radius: .06rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: .10rem;
            height: .10rem;
            background-color: #333333;
            border-radius: .05rem;
            &.hide {
              display: none;
            }
          }
        }
      }
    }
  }
}
