.hash-collection-container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	.blockchain-detail {
		width: 100%;
		min-height: 100vh;
		background: #FFFFFF;
		padding: .64rem .16rem .50rem;
		.detail-content {
			width: 100%;
			min-height: calc(100vh - 1.14rem);
			position: relative;
			padding: .58rem .12rem .73rem;
			.top-bg {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
			.content-bg {
				width: 100%;
				height: calc(100% - 1.20rem);
				position: absolute;
				top: .60rem;
				left: 0;
			}
			.bottom-bg {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			.detail-content-title {
				font-size: .16rem;
				font-weight: 600;
				color: #979797;
				line-height: .22rem;
				padding-bottom: .23rem;
				text-align: center;
			}
			.detail-content-img {
				width: 2.31rem;
				height: .16rem;
				display: block;
				margin: 0 auto;
			}
			.detail-content-img-bottom {
				transform: rotate(180deg);
			}
			.detail-content-subhead {
				font-size: .16rem;
				font-weight: 600;
				color: #000000;
				line-height: .22rem;
				text-align: center;
				padding: .04rem 0;
			}
			.detail-title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: .43rem;
				border-top: 1px solid #B7916B;
				border-bottom: 1px solid #B7916B;
				padding: 0 .11rem 0 .13rem;
				margin: .14rem 0 .20rem;
				font-size: .16rem;
				font-weight: 600;
				color: #000000;
				position: relative;
				z-index: 1;
				.icon-arrow {
					width: .13rem;
					height: .08rem;
				}
				&.down {
					border-bottom: 0;
					margin-bottom: -.14rem;
					.icon-arrow {
						transform: rotate(180deg);
					}
				}
				&:last-child {
					&.down {
						border-bottom: 1px solid #B7916B;
						margin-bottom: .20rem;
					}
				}
			}
			.info-list {
				background: #F9F9F9;
				border-radius: .06rem;
				padding: .12rem .08rem .12rem .12rem;
				font-size: .14rem;
				font-weight: 600;
				color: #979797;
				line-height: .20rem;
				position: relative;
				z-index: 1;
				.item {
					padding-bottom: .20rem;
					&:last-child {
						padding-bottom: 0;
					}
					.label {
						padding-bottom: .08rem;
					}
					.value {
						font-weight: 600;
						color: #333333;
						word-break: break-all;
						.icon-copy {
							width: .14rem;
							height: .14rem;
							margin-left: .10rem;
						}
					}
				}
				.content-headline {
					color: #979797;
					margin-bottom: .08rem;
				}
				.content-matter {
					color: #666666;
					margin-bottom: .2rem;
					word-break: break-all;
					.matter-copy {
						width: .14rem;
						height: .14rem;
						margin-left: .1rem;
					}
				}
			}
		}
	}
}
