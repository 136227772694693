.order-detail-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: .67rem .16rem .50rem;
  color: #ffffff;
	.detail-tip {
		width: 100vw;
		height: .50rem;
		margin-left: -.16rem;
		background: #1E1E23;
		padding: 0 .28rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: .30rem;
		.name {
			font-size: .14rem;
			font-weight: 400;
			color: #FFFFFF;
			&.wait {
				font-weight: 600;
			}
		}
		.reason {
			font-size: .12rem;
			font-weight: 400;
			color: #979797;
		}
		.count-down {
			display: flex;
			align-items: center;
			.label {
				font-size: .14rem;
				padding-right: .11rem;
			}
			.colon {
				display: inline-block;
				margin: 0 4px;
				font-size: .12rem;
				color: #FFFFFF;
			}
			.block {
				display: inline-block;
				width: .20rem;
				height: .20rem;
				line-height: .20rem;
				text-align: center;
				background: linear-gradient(127deg, #A0F0FF 0%, #5237FA 100%);
				border-radius: .04rem;
				font-size: .12rem;
				color: #FFFFFF;
			}
		}
	}
	.section {
		width: 100%;
		background: #1E1E23;
		border-radius: .06rem;
		padding: .15rem .12rem;
		margin-bottom: .30rem;
		&:last-child {
			margin-bottom: 0;
		}
		.title-top {
			display: flex;
			align-items: center;
			font-size: .14rem;
      padding-bottom: .15rem;
			.title-top-icon {
				width: .25rem;
				height: .25rem;
				object-fit: cover;
				margin-right: .1rem;
				border-radius: .06rem;
			}
		}
		.title-bottom {
			display: flex;
			.title-bottom-icon {
				width: 1.06rem;
				height: 1.06rem;
				object-fit: cover;
				border-radius: .06rem;
				margin-right: .15rem;
			}
			.introduce {
				width: 0;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content:space-between;
				padding: .10rem 0;
				font-size: .14rem;
        font-weight: 400;
        color: #FFFFFF;
				.pay-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
				}
        .title {
					font-size: .16rem;
					font-weight: 600;
          padding-bottom: .10rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
				.hash {
					font-size: .12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
				}
        .name {
          color: #979797;
        }
        .quantity {
          text-align: right;
        }
				.price {
					font-size: .18rem;
					font-weight: 600;
				}
			}
		}
		.mode-payment-title {
			font-size: .14rem;
			font-weight: 600;
		}
		.mode-payment-content {
			display: flex;
			align-items: center;
			margin-top: .16rem;
			font-size: .14rem;
			.mode-payment-icon {
				width: .26rem;
				height: .21rem;
				margin-right: .1rem;
			}
		}
		.add-bankcard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .10rem;
      font-weight: 400;
      color: #979797;
      line-height: .14rem;
      padding-top: .10rem;
      padding-left: .36rem;
      .arrow-icon {
        width: .06rem;
        height: .10rem;
      }
    }
		.bankcard-super {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .10rem;
      font-weight: 400;
      color: #979797;
      line-height: .14rem;
      padding-top: .10rem;
      .block {
        display: flex;
        align-items: center;
        .bank-icon {
          width: .20rem;
          height: .20rem;
          margin-right: .15rem;
        }
        .arrow-icon {
          width: .06rem;
          height: .10rem;
          margin-left: .10rem;
        }
      }
    }
		.item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .14rem;
      padding-bottom: .15rem;
      &:last-child {
        padding-bottom: 0;
      }
      .value {
        color: #979797;
      }
      .hash-super {
        display: flex;
        align-items: center;
        width: 60%;
        .hash {
          color: #979797;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: right;
        }
        .name-qukuailian {
          width: .14rem;
          height: .14rem;
					padding-left: 0.05rem;
        }
      }
    }
	}
	.footer {
		width: 100%;
		height: .50rem;
		position: fixed;
    background: #100D20;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 .16rem;
		font-size: .14rem;
		.button {
			width: 1.2rem;
			height: .35rem;
			line-height: .35rem;
			text-align: center;
			background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .14rem;
			font-weight: 600;
			&.cancel {
				background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png)
			}
		}
	}
	.card-selector {
    width: 100%;
    background: #F2F2F2;
    position: absolute;
    bottom: 0;
    color: #000000;
    padding: .15rem .15rem .39rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .15rem;
      right: .15rem;
    }
    .title {
      font-size: .16rem;
      font-weight: 600;
      line-height: .22rem;
      text-align: center;
    }
    .list-super {
      padding-top: .30rem;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .30rem;
        .card-info {
          font-size: .10rem;
          font-weight: 500;
          line-height: .14rem;
          display: flex;
          align-items: center;
          .bank-icon {
            width: .20rem;
            height: .20rem;
            margin-right: .13rem;
          }
        }
        .choose-icon {
          width: .14rem;
          height: .11rem;
        }
      }
    }
    .add-super {
      font-size: .10rem;
      font-weight: 500;
      line-height: .14rem;
      display: flex;
      align-items: center;
      .add-icon {
        width: .20rem;
        height: .20rem;
        margin-right: .13rem;
      }
    }
  }
  .code-super {
    width: 3.42rem;
    background: #1E1C28;
    border-radius: .06rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: .15rem .30rem .20rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .19rem;
      right: .30rem;
    }
    .title {
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .22rem;
      text-align: center;
    }
    .content {
      padding-top: .20rem;
      padding-bottom: .40rem;
      .phone {
        height: .32rem;
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .22rem;
        border-bottom: 1px solid #4B4953;
        padding-bottom: .10rem;
        margin-bottom: .28rem;
      }
      .adm-input {
        padding-bottom: .10rem;
        border-bottom: 1px solid #4B4953;
        .adm-input-element {
          font-size: .16rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .25rem;
          &::placeholder {
            color: #979797;
          }
        }
      }
    }
    .countdown-btn {
      width: .70rem;
      height: .30rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .10rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .30rem;
      text-align: center;
      position: absolute;
      top: 1.15rem;
      right: .30rem;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      &.disable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      }
    }
  }
}
