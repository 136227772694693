.header-white-container {
  width: 100%;
  height: .44rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-arrow {
    width: .41rem;
    height: 100%;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-arrow.png);
    background-size: .09rem .15rem;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .title {
    font-weight: 600;
    color: #333333;
    font-size: .16rem;
  }
  .blank {
    width: .41rem;
  }
}
