.abandon-code-container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/login-bg.png);
  background-size: 100% 100%;
  .title {
    font-size: .20rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .28rem;
    text-align: center;
    padding: 1.24rem 0 .15rem;
  }
  .tip {
    font-size: .12rem;
    font-weight: 600;
    color: #979797;
    line-height: .17rem;
    text-align: center;
    padding-bottom: .69rem;
    .send-again {
      margin-left: .08rem;
      &.enable {
        color: #88B6FD;
      }
    }
  }
  .content {
    padding: 0 .28rem;
    .code-input-super {
      width: 100%;
      height: 100%;
      position: relative;
      .adm-input {
        padding-bottom: .10rem;
        margin-bottom: .28rem;
        position: relative;
        z-index: 2;
        .adm-input-element {
          font-size: .18rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .25rem;
          padding-left: .15rem;
          letter-spacing: .46rem;
        }
      }
      .box-list {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        .box-item {
          height: 100%;
          width: 12%;
          border-bottom: 1px solid rgba(151, 151, 151, .2);
        }
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      line-height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
  .modal-contact-content {
    margin: 1.34rem auto 0;
    width: 2.52rem;
    height: 3.06rem;
    background: #1E1C28;
    border-radius: .06rem;
    opacity: 0.9;
    text-align: center;
    .mask-title {
      color: #FFFFFF;
      font-size: .16rem;
      font-weight: 600;
      padding-top: .48rem;
    }
    .mask-img {
      width:  1.2rem;
      height: 1.2rem;
      object-fit: contain;
      margin-top: .38rem;
    }
    .mask-hint {
      font-size: .14rem;
      color: #979797;
      margin-top: .1rem;
    }
  }
}
