.reword-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #ffffff;
  .reword-list {
    padding: .20rem .16rem 0;
    .item {
      padding: .08rem 0;
      border-bottom: 1px solid #eee;
      display: flex;
      &:last-child {
        border-bottom: 0;
      }
      .pic-super {
        width: .85rem;
        height: .85rem;
        border-radius: .08rem;
        margin-right: .16rem;
        background-color: #FFF6F9;
        display: flex;
        align-items: center;
        justify-content: center;
        .face {
          width: .47rem;
          height: .52rem;
        }
      }
      .pic {
        width: .85rem;
        height: .85rem;
        object-fit: cover;
        border-radius: .08rem;
        margin-right: .16rem;
      }
      .content {
        display: flex;
        flex: 1;
        justify-content: space-between;
        .name {
          font-size: .15rem;
          font-weight: 500;
          color: #333333;
          line-height: .21rem;
          padding-top: .13rem;
        }
        .time {
          font-size: .13rem;
          font-weight: 400;
          color: #999999;
          line-height: .18rem;
          padding-top: .20rem;
        }
        .status {
          font-size: .15rem;
          font-weight: 500;
          line-height: .21rem;
          padding-top: .13rem;
          padding-left: .11rem;
        }
        .btn {
          width: .66rem;
          height: .26rem;
          padding-top: .17rem;
        }
      }
    }
  }
}