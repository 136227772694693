.bill-detail-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding-top: .54rem;
  background-color: #FBFBFB;
  .bill-money-super {
    text-align: center;
    position: relative;
    .title {
      font-size: .16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: .22rem;
      padding-bottom: .20rem;
    }
    .amount {
      font-size: .30rem;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #333333;
      line-height: .35rem;
      padding-bottom: .08rem;
      display: flex;
      justify-content: center;
      .prev {
        font-family: PingFangSC-Semibold, PingFang SC;
        margin-top: -0.03rem;
      }
      .mark {
        padding-left: .05rem;
        padding-right: .10rem;
      }
    }
    .balance {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: .20rem;
      padding-bottom: .20rem;
    }
    .border {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .bill-process-super {
    padding: .29rem 0 0 .47rem;
    position: relative;
    display: flex;
    .label {
      font-size: .14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: .20rem;
      padding-right: .50rem;
    }
    .process-list {
      position: relative;
      .item {
        font-size: .12rem;
        font-weight: 400;
        color: #666666;
        height: .70rem;
        position: relative;
        &.active {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .dot {
          width: .15rem;
          height: .15rem;
          position: absolute;
          top: 0;
          left: -.30rem;
        }
        .name {
          padding-bottom: .05rem;
        }
      }
      .line {
        width: .03rem;
        height: 1.91rem;
        position: absolute;
        top: -.16rem;
        left: -.24rem;
      }
    }
    .border {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .bill-info-super {
    padding: .29rem .16rem 0;
    .item {
      display: flex;
      justify-content: space-between;
      padding-bottom: .20rem;
      .label {
        font-size: .14rem;
        font-weight: 400;
        color: #666666;
        line-height: .20rem;
      }
      .value {
        font-size: .14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: .20rem;
      }
    }
  }
}
