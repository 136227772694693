.subgift-status-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .16rem .50rem;
  .status-super {
    padding: .32rem 0 .40rem;
    text-align: center;
    .icon {
      width: .46rem;
      height: .50rem;
      margin-bottom: .26rem;
    }
    .name {
      font-size: .20rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
      padding-bottom: .20rem;
    }
    .tip {
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .collection-super {
    width: 100%;
    background: #1E1E23;
    border-radius: .06rem;
    padding: .15rem .12rem;
    display: flex;
    .main-pic {
      width: 1.00rem;
      height: 1.00rem;
      margin-right: .20rem;
    }
    .content {
      width: 0;
      flex: 1;
      padding-top: .01rem;
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      .name {
        font-size: .16rem;
        font-weight: 600;
        line-height: .22rem;
        padding-bottom: .05rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text {
        padding-top: .05rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .info-list {
    padding: .40rem .12rem .61rem;
    .item {
      display: flex;
      justify-content: space-between;
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .15rem;
      &:last-child {
        padding-bottom: 0;
      }
      .label {
        color: #979797;
      }
    }
  }
  .btn-confirm {
    width: 3.19rem;
    height: .45rem;
    margin: 0 auto;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    background-size: 100% 100%;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .45rem;
    text-align: center;
  }
  .tip-text {
    font-size: .12rem;
    font-weight: 400;
    color: #979797;
    line-height: .17rem;
    text-align: center;
    padding-top: .15rem;
  }
  .btn-super {
    width: 3.19rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: .14rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .35rem;
    text-align: center;
    .btn-complete {
      width: 1.20rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-disable.png);
      background-size: 100% 100%;
    }
    .btn-look {
      width: 1.20rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
    }
  }
}