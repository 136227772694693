.create-avatar-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding-top: 1.17rem;
  background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/create-avatar-bg.png);
  background-size: 100% 100%;
  .avatar-super {
    width: 3.00rem;
    height: 3.00rem;
    margin: 0 auto;
    border-radius: .06rem;
    background-color: #2D2022;
    border: 1px solid #2D2022; // 边框解决背景色不显示问题
    .pic {
      width: 3.00rem;
      height: 3.00rem;
      border-radius: 1.50rem;
      object-fit: cover;
    }
  }
  .confirm-btn {
    width: 3.19rem;
    height: .45rem;
    margin: 1.00rem auto 0;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
    background-size: 100% 100%;
    font-size: .16rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .45rem;
    text-align: center;
  }
}