.creator-tags-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .16rem 0 .16rem;

  .item {
    padding-bottom: .20rem;

    .label-super {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .20rem;
      padding-bottom: .20rem;

      .require-star {
        color: #FF2300;
      }
    }
  }

  .creator-tags-btn {
    background-color: #1E1E23;
    padding: .10rem .12rem;
    border-radius: .06rem;
    margin-bottom: .20rem;
  }

  .adm-checkbox.adm-checkbox-checked {
    .adm-checkbox-icon {
      border-color: #88B6FD;
      background-color: #88B6FD;
    }
  }

  .submit-super {
    width: 100%;
    background-color: #100D20;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: .20rem 0 .34rem;
    .submit-btn {
      width: 3.19rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
    }
  }
}