.topic-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #FFFFFF;
  padding-top: .44rem;
  .icon-share {
    width: .16rem;
    height: .14rem;
    position: fixed;
    top: .14rem;
    right: .16rem;
    z-index: 1001;
  }
  .topic-banner {
    width: 100%;
    aspect-ratio: 1.80;
    object-fit: cover;
  }
  .topic-content {
    padding: .15rem .16rem .40rem;
    .info {
      width: 100%;
      background-color: #1E1E23;
      border-radius: .06rem;
      padding: .20rem .14rem;
      margin-bottom: .15rem;
      .name {
        font-size: .18rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .25rem;
        padding-bottom: .15rem;
      }
      .desc {
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .24rem;
        letter-spacing: 1px;
      }
      .pic {
        width: 100%;
        margin-top: .20rem;
        // 移动端禁止下载图片
        // user-select: none;
        // pointer-events:none;
      }
    }
    .product-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: .10rem;
      .product-item {
        background: #1E1E23;
        border-radius: .06rem;
        position: relative;
        min-width: 0;
        .main-img {
          width: 100%;
          height: 1.66rem;
          object-fit: cover;
          border-radius: .06rem;
        }
        .content-super {
          padding: .05rem .12rem .10rem;
          position: relative;
          .title {
            font-size: .16rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .22rem;
            padding-bottom: .05rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tag-super {
            display: flex;
            font-size: .11rem;
            padding-bottom: .08rem;
            .limit-super {
              display: flex;
              margin-right: .05rem;
              .limit-label {
                background: linear-gradient(127deg, #A0F0FF 0%, #5237FA 100%);
                border-radius: 1px 0px 0px 1px;
                height: .16rem;
                line-height: .16rem;
                padding: 0 .05rem;
                font-weight: 600;
                color: #06213B;
              }
              .limit-num {
                background: #3B3348;
                border-radius: 0 1px 1px 0;
                height: .16rem;
                line-height: .16rem;
                padding: 0 .06rem;
                font-weight: 400;
                color: #FFFFFF;
              }
            }
          }
          .price {
            font-size: .18rem;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .22rem;
            text-align: right;
          }
        }
        .sell-status-super {
          position: absolute;
          top: .10rem;
          left: .12rem;
          height: .15rem;
          line-height: .15rem;
          background: #100D20;
          border-radius: .08rem;
          padding: 0 .06rem;
          font-size: .08rem;
          font-weight: 400;
          display: flex;
          align-items: center;
          &.sell-fire {
            color: #EF715D;
          }
          &.sell-time {
            color: #60C167;
          }
          &.sell-enpty {
            color: #969696;
          }
          .fire-icon {
            width: .07rem;
            height: .08rem;
            margin-right: .03rem;
          }
          .time-icon {
            width: .08rem;
            height: .08rem;
            margin-right: .03rem;
          }
        }
      }
    }
  }
}
  