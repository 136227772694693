.abandon-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .88rem .16rem 0;
  .content {
    text-align: center;
    .label {
      font-size: .14rem;
      font-weight: 400;
      color: #979797;
      line-height: .20rem;
      padding-bottom: .15rem;
    }
    .mobile {
      font-size: .20rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
      padding-bottom: .30rem;
    }
    .desc {
      padding: .20rem .05rem .15rem .12rem;
      text-align: left;
      background: #1E1C28;
      border-radius: .06rem;
      .title {
        font-size: .16rem;
        font-weight: 600;
        padding-bottom: .20rem;
      }
      .text {
        font-size: .14rem;
        font-weight: 400;
        padding-bottom: .20rem;
      }
    }
    .confirm-btn {
      width: 100%;
      height: .45rem;
      line-height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      margin-top: .30rem;
    }
  }
}
