.about-us-container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	color: #ffffff;
	.about-us-container-content {
		position: fixed;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		.about-us-container-icon-img {
			width: 1.18rem;
			height: 1.18rem;
		}
		.about-us-container-name {
			font-weight: 600;
			font-size: .16rem;
			margin-top: .2rem;
		}
		.about-us-container-version {
			margin-top: .05rem;
			font-size: .12rem;
			color: #979797;
		}
	}
	.about-us-container-footer {
		width: 100%;
		position: fixed;
		bottom: .40rem;
		left: 0;
		.license-list {
      display: flex;
      align-items: center;
			justify-content: center;
      font-size: .10rem;
      font-weight: 400;
      color: #999999;
			padding-bottom: .13rem;
      .license-item {
        padding-right: .10rem;
        display: flex;
        align-items: center;
        .police-logo {
          width: .14rem;
          height: .14rem;
          padding-right: .06rem;
        }
        &:last-child {
          padding: 0;
        }
      }
    }
		.agree-super {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: .12rem;
			font-weight: 400;
			color: #88B6FD;
			.agree-item {
				padding-right: .10rem;
				margin-right: .10rem;
				border-right: 1px solid #88B6FD;
			}
		}
	}
}
