.sale-order-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #FBFBFB;
  padding: .85rem 0 .20rem;
  .header-super {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FBFBFB;
  }
  .sale-order-header-container {
    width: 100%;
    height: .44rem;
    display: flex;
    align-items: center;
    position: relative;
    .icon-arrow {
      position: absolute;
      top: 0;
      left: 0;
      width: .41rem;
      height: .44rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/icon-black-arrow.png);
      background-size: .09rem .15rem;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 1;
    }
    .adm-tabs {
      width: 100%;
    }
    .adm-tabs-header {
      border-bottom: 0;
    }
    .adm-tabs-tab-line {
      display: none;
    }
    .adm-tabs-tab {
      width: 1.17rem;
      text-align: center;
      font-size: .16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding: .08rem 0;
      color: #333333;
      &.adm-tabs-tab-active {
        color: #88B6FD;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/sale-order-tab-line.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% 1px;
      }
    }
  }
  .tabs-super {
    padding-top: .13rem;
    .adm-tabs {
      width: 100%;
    }
    .adm-tabs-tab-wrapper {
      padding: 0;
    }
    .adm-tabs-header {
      border-bottom: 0;
    }
    .adm-tabs-tab-line {
      display: none;
    }
    .adm-tabs-tab {
      width: 100%;
      text-align: center;
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding: .04rem 0;
      color: #333333;
      &.adm-tabs-tab-active {
        color: #88B6FD;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/sale-order-tab-line.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% 1px;
      }
    }
  }
  .order-list {
    padding: .11rem .16rem 0;
    .item {
      width: 100%;
      padding: .10rem .12rem;
      background: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgba(124,155,253,0.2);
      border-radius: .06rem;
      display: flex;
      margin-bottom: .15rem;
      &:last-child {
        margin-bottom: 0;
      }
      .pic {
        width: 1.06rem;
        height: 1.06rem;
        border-radius: .06rem;
        object-fit: cover;
        margin-right: .15rem;
      }
      .block {
        width: 0;
        flex: 1;
				padding-top: .10rem;
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-child {
            padding: 0;
          }
          .name {
            font-size: .18rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: .25rem;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .status {
            font-size: .16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: .22rem;
            &.wait {
              color: #E2AF1C;
            }
            &.done {
              color: #88B6FD;
            }
            &.cancel {
              color: #999999;
            }
          }
          .price {
            font-size: .18rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: .25rem;
            span {
              font-size: .16rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: .22rem;
            }
          }
          .btn {
            width: .56rem;
            height: .22rem;
          }
        }
        .token {
          padding: .05rem 0 .10rem;
          font-size: .16rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .22rem;
        }
      }
    }
  }
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
      opacity: .5;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #666666;
      line-height: .22rem;
    }
  }
}
