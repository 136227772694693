.compose-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding: .60rem .16rem .50rem;
  background-color: #FFFFFF;
  .compose-banner-super {
    width: 100%;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-machine-bg.png);
    background-size: 100% 100%;
    border-radius: .06rem;
    position: relative;
    margin-bottom: .12rem;
    .machine {
      width: 2.01rem;
      height: 2.90rem;
      position: absolute;
      top: .13rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .rule {
      width: .36rem;
      height: .36rem;
      position: absolute;
      top: .43rem;
      right: .13rem;
    }
    .content {
      position: relative;
      padding-bottom: .26rem;
      .title {
        box-sizing: content-box;
        height: .30rem;
        font-size: .16rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .30rem;
        text-align: center;
        padding-top: .41rem;
        width: 1.20rem;
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .count-super {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: .12rem;
        font-weight: 600;
        color: #102D6B;
        line-height: .16rem;
        padding: 1.82rem .83rem .30rem .88rem;
        .text {
          width: .50rem;
          text-align: center;
        }
      }
      .compose-btn {
        width: 1.99rem;
        height: .30rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-btn-enable.png);
        background-size: 100% 100%;
        font-size: .16rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: .30rem;
        text-align: center;
        margin: 0 auto;
        &.disable {
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-btn-disable.png);
        }
      }
    }
    .arrow-super {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 1.30rem;
      left: 0;
      padding: 0 .11rem;
      .arrow {
        width: .30rem;
        height: .30rem;
        &.prev {
          transform: rotateY(180deg)
        }
      }
    }
  }
  .compose-material-super {
    padding: .13rem .20rem .25rem .15rem;
    // background: linear-gradient(132deg, rgba(160,240,255,0.2) 0%, rgba(82,55,250,0.1) 52%, rgba(82, 55, 250, 0.10) 90%, rgba(160,240,255,0.2) 120%);
    // box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3);
    // border-radius: .06rem;
    margin-bottom: .12rem;
    position: relative;
    .material-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-image-source: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-material-bg.png);
      border-image-slice: 62 40 40 260 fill;
      border-top: .31rem solid;
      border-right: .20rem solid;
      border-bottom: .20rem solid;
      border-left: 1.30rem solid;
    }
    .material-title {
      font-size: .16rem;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      padding-bottom: .23rem;
      position: relative;
    }
    .material-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: .29rem;
      row-gap: .27rem;
      position: relative;
      .item {
        width: 100%;
        position: relative;
        &.disable {
          filter: grayscale(100%);
        }
        .pic {
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: .06rem;
          margin-bottom: .14rem;
          vertical-align: sub;
        }
        .name {
          width: .81rem;
          height: .21rem;
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-name-enable.png);
          background-size: 100% 100%;
          font-size: .12rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .21rem;
          text-align: center;
          margin: 0 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 .08rem;
        }
        .need {
          position: absolute;
          top: -.06rem;
          right: 0;
          width: .34rem;
          height: .14rem;
          background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-need-bg.png);
          background-size: 100% 100%;
          font-size: .12rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: .14rem;
          text-align: center;
        }
      }
    }
  }
  .compose-intro-super {
    padding: .23rem .14rem .25rem .15rem;
    background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/compose-intro-bg.png);
    background-size: 100% 100%;
    .intro-title {
      font-size: .16rem;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      padding-bottom: .15rem;
    }
    .text {
      font-size: .14rem;
      font-weight: 400;
      color: #333333;
      padding-bottom: .15rem;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .compose-add-modal {
    width: 100%;
    background: #1E1C28;
    border-radius: .20rem .20rem 0 0;
    padding: .30rem .16rem;
    position: absolute;
    left: 0;
    bottom: 0;
    .title {
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .22rem;
      text-align: center;
    }
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .20rem;
      right: .28rem;
    }
    .enpty-super {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1.15rem;
      .icon-enpty {
        width: 2.14rem;
        height: 1.24rem;
      }
      .enpty-tip {
        font-size: .14rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .20rem;
        padding-top: .40rem;
        padding-bottom: 1.42rem;
      }
    }
    .content-super {
      padding-top: .30rem;
      .collection-list {
        width: 100%;
        height: 3.36rem;
        overflow: auto;
        margin-bottom: .30rem;
        .item {
          width: 100%;
          height: .74rem;
          position: relative;
          margin-bottom: .10rem;
          background-color: #FFFFFF;
          border-radius: .06rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: .12rem;
          .info-super {
            display: flex;
            flex: 1;
            .img {
              width: .50rem;
              height: .50rem;
              border-radius: .06rem;
              margin-right: .10rem;
            }
            .text-super {
              width: 0;
              flex: 1;
              display: flex;
              padding: .06rem 0;
              flex-direction: column;
              justify-content: space-between;
              .name {
                font-size: .12rem;
                font-weight: 600;
                color: #333333;
                line-height: .17rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .no {
                font-size: .10rem;
                font-weight: 400;
                color: #666666;
                line-height: .14rem;
              }
            }
          }
          .icon-check {
            width: .12rem;
            height: .12rem;
          }
          .disable-mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .3);
            border-radius: .06rem;
          }
        }
      }
    }
    .confirm-btn {
      width: 2.82rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
    }
  }
  .compose-rule-modal {
    width: 100%;
    background: #FFFFFF;
    border-radius: .20rem .20rem 0 0;
    padding: .30rem .16rem;
    position: absolute;
    left: 0;
    bottom: 0;
    .title {
      font-size: .16rem;
      font-weight: 600;
      color: #333333;
      line-height: .22rem;
      text-align: center;
    }
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .20rem;
      right: .28rem;
    }
    .rule-info {
      width: 100%;
      height: 4.26rem;
      overflow: auto;
      background: #F2F2F2;
      border-radius: .06rem;
      padding: .12rem;
      margin: .20rem 0;
    }
  }
  .compose-success-modal {
    width: 3.43rem;
    border-radius: .06rem;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-img {
      width: 3.19rem;
      height: .86rem;
      margin-top: .41rem;
    }
    .top-tip {
      padding-top: .55rem;
      font-size: .20rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
    }
    .award-img {
      width: 1.60rem;
      height: 1.60rem;
      border-radius: .06rem;
      margin-top: .15rem;
    }
    .award-name {
      font-size: .20rem;
      font-weight: 600;
      color: #E2AF1C;
      line-height: .28rem;
      padding-top: .15rem;
    }
    .bottom-tip {
      font-size: .16rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .22rem;
      padding-top: .15rem;
    }
    .continue-btn {
      width: 2.82rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin-top: .30rem;
      position: relative;
      z-index: 2;
    }
    .icon-close {
      width: .20rem;
      height: .20rem;
      margin-top: .15rem;
      margin-bottom: .09rem;
      position: relative;
      z-index: 2;
    }
    .star-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .compose-fail-modal {
    width: 3.43rem;
    border-radius: .06rem;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .50rem 0 .20rem;
    .title-img {
      width: 3.19rem;
      height: .97rem;
    }
    .top-tip {
      padding-top: .29rem;
      font-size: .20rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .28rem;
    }
    .bottom-tip {
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .22rem;
      padding-top: .13rem;
    }
    .continue-btn {
      width: 2.82rem;
      height: .45rem;
      background-color: #000000;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin-top: .30rem;
    }
    .icon-close {
      width: .20rem;
      height: .20rem;
      margin-top: .15rem;
    }
  }
}
