.collection-createDetail-container {
  width: 100%;
  margin: 0 auto;
  padding-top: .62rem;
  color: #ffffff;

  .content-top {
    width: 100%;
    background: #1E1E23;
    padding: .15rem .28rem;

    .content-top-title {
      font-size: .14rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .20rem;
    }

    .content-top-text {
      font-size: .12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .17rem;
      margin-top: .1rem;
    }
  }

  .section-con {
    padding: 0 .16rem;

    .section {
      width: 100%;
      padding: .15rem .12rem;
      margin-top: .15rem;
      background: #1E1E23;
      border-radius: .06rem;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .14rem;
        padding-bottom: .15rem;

        &:last-child {
          padding-bottom: 0;
        }

        .value {
          color: #FFFFFF;
          flex: 1;
          width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }

        .value-btn {
          width: .84rem;
          height: .25rem;
          background: linear-gradient(139deg, #9DE9FF 0%, #7488FC 100%);
          border-radius: .04rem;
          line-height: .25rem;
          text-align: center;
          font-size: .12rem;
        }
      }

      .title {
        font-size: .18rem;
        font-weight: 600;
      }

      .desc-list {
        .desc-item {
          font-size: .14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: .20rem;
          padding-bottom: .15rem;

          &:last-child {
            padding-bottom: 0;
          }

          .desc-label {
            color: #FFFFFF;
            padding-bottom: .05rem;
          }

          .desc-value {
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
