.creator-publish-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .25rem .99rem .16rem;
  .form-list {
    .item {
      padding-bottom: .20rem;
      &:last-child {
        padding-bottom: 0;
      }
      .label-super {
        font-size: .14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: .20rem;
        padding-bottom: .10rem;
        .require-star {
          color: #FF2300;
        }
        .label-tip {
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #979797;
          line-height: .17rem;
          padding-left: .10rem;
        }
      }
      .input-super {
        background: #1E1E23;
        border-radius: .06rem;
        padding: .10rem .12rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        line-height: .17rem;
        .value {
          color: #FFFFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info-super {
          display: flex;
          align-items: center;
          .main-pic {
            width: .54rem;
            height: .54rem;
            object-fit: cover;
            border-radius: .06rem;
            margin-right: .15rem;
          }
          .input-tip {
            padding-bottom: .12rem;
          }
        }
        .icon-arrow {
          width: .08rem;
          height: .14rem;
          transform: rotate(180deg);
          margin-left: .10rem;
        }
        .prefix {
          color: #FFFFFF;
          margin-right: .05rem;
        }
        .input {
          background-color: transparent;
          border: 0;
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: .17rem;
          flex: 1;
        }
        .suffix {
          display: flex;
          align-items: center;
          .icon-option {
            width: .20rem;
            height: .20rem;
          }
          .number {
            font-size: .14rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            padding: 0 .20rem;
          }
        }
      }
      .item-tip {
        font-size: .12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF2300;
        line-height: .17rem;
        padding-top: .05rem;
        padding-left: .12rem;
      }
    }
  }
  .submit-super {
    width: 100%;
    background-color: #100D20;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: .20rem 0 .34rem;
    .submit-btn {
      width: 3.19rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
    }
  }
  .mask-agreement {
    width: 3.43rem;
    height: calc(100vh - .69rem);
    background: #1E1E23;
    border-radius: .06rem;
    position: absolute;
    left: 50%;
    bottom: .25rem;
    transform: translateX(-50%);
    .icon-close {
      width: .14rem;
      height: .14rem;
      position: absolute;
      top: .19rem;
      right: .12rem;
    }
    .agreement-title {
      font-size: .16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      height: .52rem;
      line-height: .52rem;
      text-align: center;
    }
    .agreement-img-container {
      width: 100%;
      height: calc(100% - 1.17rem);
      overflow-y: scroll;
      padding-left: .12rem;
      padding-right: .28rem;
      margin-bottom: .10rem;
      .agreement-all-img {
        width: 100%;
      }
    }
    .agreement-submit {
      width: 3.19rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin-left: .12rem;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
}
