.creator-major-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    color: #ffffff;
    padding: .62rem .25rem .99rem .16rem;
    .form-list {
      .item {
        padding-bottom: .20rem;
        &:last-child {
          padding-bottom: 0;
        }
        .label-super {
          font-size: .14rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: .20rem;
          padding-bottom: .10rem;
          .require-star {
            color: #FF2300;
          }
          .label-tip {
            font-size: .12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #979797;
            line-height: .17rem;
            padding-left: .10rem;
          }
        }
        .input-super {
          background: #1E1E23;
          border-radius: .06rem;
          padding: .10rem .12rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #979797;
          line-height: .17rem;
          .input-tip {
            padding-bottom: .12rem;
          }
          .icon-arrow {
            width: .08rem;
            height: .14rem;
            transform: rotate(180deg);
          }
          .prefix {
            color: #FFFFFF;
          }
          .input {
            background-color: transparent;
            border: 0;
            font-size: .12rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .17rem;
            flex: 1;
          }
        }
        .input-hint {
          padding-bottom: .10rem;
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #979797;
        }
        .works-type {
          display: flex;
          .type {
            width: .6rem;
            height: .6rem;
            background-size: 100% 100%;
            border-radius: .06rem;
          }
          .type-image {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/image-icon.png);
            margin-right: .15rem;
            &.enable {
              background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/image-icon-active.png);
            }
          }
          .type-video {
            background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/video-icon.png);
            &.enable {
              background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/video-icon-active.png);
            }
          }
        }
        .item-tip {
          font-size: .12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF2300;
          line-height: .17rem;
          padding-top: .05rem;
          padding-left: .12rem;
        }
      }
    }
    .submit-super {
      width: 100%;
      background-color: #100D20;
      position: fixed;
      left: 0;
      bottom: 0;
      padding: .20rem 0 .34rem;
      .submit-btn {
        width: 3.19rem;
        height: .45rem;
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
        background-size: 100% 100%;
        font-size: .16rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: .45rem;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  