.record-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .16rem .20rem;
  .enpty-super {
    padding-top: 1.61rem;
    text-align: center;
    .enpty-img {
      width: 2.14rem;
      height: 1.24rem;
    }
    .text {
      padding-top: .41rem;
      font-size: .16rem;
      font-weight: 400;
      color: #6E6B7C;
      line-height: .22rem;
    }
  }
  .record-list {
    .item {
      padding: .15rem .12rem;
      background: #1E1E23;
      border-radius: .06rem;
      margin-bottom: .15rem;
      font-size: .14rem;
      font-weight: 400;
      line-height: .20rem;
      .top-super {
        display: flex;
        justify-content: space-between;
        padding-bottom: .10rem;
        .content {
          display: flex;
          flex: 1;
          .img {
            width: .70rem;
            height: .70rem;
            border-radius: .06rem;
            object-fit: cover;
            margin-right: .10rem;
          }
          .desc-super {
            flex: 1;
            max-width: 1.4rem;
            .title {
              padding: .05rem 0 .20rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .creator {
              font-weight: 600;
              color: #979797;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .status {
          padding-top: .05rem;
          &.trade {
            color: #60C167;
          }
          &.send {
            color: #E2AF1C;
          }
          &.receive {
            color: #88B6FD;
          }
        }
      }
      .row {
        padding-top: .10rem;
        display: flex;
        justify-content: space-between;
        .value {
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
      }
    }
  }
}