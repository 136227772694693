.login-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .top-super {
    display: flex;
    align-items: center;
    height: .44rem;
    padding-left: .16rem;
    .icon-close {
      width: .14rem;
      height: .14rem;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .27rem .08rem .20rem .28rem;
    .title {
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: .24rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .33rem;
    }
    .banner {
      width: 1.84rem;
      height: 1.36rem;
    }
  }
  
  .content {
    padding: 0 .28rem;
    .adm-input {
      height: .40rem;
      line-height: .40rem;
      margin-bottom: .20rem;
      background: #1E1E23;
      border-radius: .06rem;
      padding: 0 .12rem;
      .adm-input-element {
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        &::placeholder {
          color: #979797;
        }
      }
    }
    .sms-code-super {
      position: relative;
      .adm-input {
        padding-right: 1.05rem;
      }
      .extra-super {
        position: absolute;
        top: 0;
        right: 0;
        width: .93rem;
        height: .40rem;
        line-height: .40rem;
        display: flex;
        align-items: center;
      }
      .extra {
        width: 100%;
        height: .15rem;
        line-height: .15rem;
        font-size: .14rem;
        font-weight: 400;
        border-left: 1px solid #979797;
        text-align: center;
        &.btn {
          color: #88B6FD;
        }
        &.second {
          color: #979797;
        }
      }
    }
    .code-btn {
      width: 100%;
      height: .45rem;
      line-height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
    .agree-super {
      padding-bottom: .50rem;
      display: flex;
      align-items: center;
      font-size: .12rem;
      font-weight: 400;
      color: #979797;
      .icon-checkbox {
        width: .12rem;
        height: .12rem;
        margin-right: .10rem;
      }
      .link {
        color: #88B6FD;
      }
    }
  }
}
