.agree-container {
  .agree-content-super {
    padding: .22rem .16rem;
    font-size: .16rem;
    color: #000000;
    line-height: .22rem;
    word-break: break-all;
    background-color: #FFFFFF;
    position: relative;
    .agree-title {
      font-weight: 600;
      text-align: center;
    }
    .agree-img {
      width: 100%;
      padding: .40rem 0
    }
    .anchor-sdk {
      position: absolute;
      left: 0;
      top: 10.76rem;
    }
  }
}

