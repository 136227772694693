.search-user-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .62rem .16rem 0;
  .form-super {
    display: flex;
    justify-content: space-between;
    position: relative;
    .adm-input {
      width: 3rem;
      height: .30rem;
      background: #1E1E23;
      border-radius: .06rem;
      padding-left: .41rem;
      .adm-input-element {
        font-size: .14rem;
        font-weight: 600;
        color: #FFFFFF;
        &::placeholder {
          color: #979797;
        }
      }
    }
    .icon-search {
      width: .14rem;
      height: .14rem;
      position: absolute;
      left: .12rem;
      top: .09rem;
    }
    .search-btn {
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .30rem;
    }
  }
  .enpty-super {
    padding-top: .42rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-enpty {
      width: .47rem;
      height: .50rem;
    }
    .tip {
      font-size: .16rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .22rem;
      padding: .43rem 0 .55rem;
      text-align: center;
    }
    .btn {
      width: 1.20rem;
      height: .35rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/btn-enable.png);
      background-size: 100% 100%;
      font-size: .14rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .35rem;
      text-align: center;
    }
  }
  .user-super {
    padding-top: .30rem;
    .tip {
      padding-left: .12rem;
      padding-bottom: .20rem;
      width: 100%;
      border-bottom: 1px solid #3F3D4C;
      font-size: .14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .20rem;
    }
    .content {
      padding: .20rem .12rem 1.03rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info {
        display: flex;
        align-items: center;
        font-size: .16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .22rem;
        .avatar {
          width: .36rem;
          height: .36rem;
          border-radius: .18rem;
          margin-right: .15rem;
        }
      }
      .icon-radio {
        width: .14rem;
        height: .14rem;
      }
    }
    .btn {
      width: 2.82rem;
      height: .45rem;
      background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-disable.png);
      background-size: 100% 100%;
      font-size: .16rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: .45rem;
      text-align: center;
      margin: 0 auto;
      &.enable {
        background-image: url(https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/nft/long-btn-enable.png);
      }
    }
  }
}