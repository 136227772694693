.invite-record-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .64rem .16rem 0;
  .content {
    .enpty-super {
      padding-top: 1.50rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .16rem;
      font-weight: 400;
      line-height: .22rem;
      padding-bottom: .10rem;
      .enpty-img {
        width: 2.14rem;
        height: 1.24rem;
        margin-bottom: .52rem;
      }
      .text {
        color: #6E6B7C;
        padding-bottom: .10rem;
      }
    }
    .list-super {
      width: 100%;
      background: #1E1E23;
      border-radius: .06rem;
      padding: .15rem .12rem;
      font-size: .14rem;
      line-height: .20rem;
      .head-super {
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        padding: 0 .10rem .10rem;
      }
      .item {
        font-weight: 400;
        color: #979797;
        display: flex;
        justify-content: space-between;
        padding-bottom: .15rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
