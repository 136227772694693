.welfare-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: .44rem .16rem 0;
  .welfare-content {
    .enpty-super {
      padding-top: 1.50rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .16rem;
      font-weight: 400;
      line-height: .22rem;
      padding-bottom: .10rem;
      .enpty-img {
        width: 2.14rem;
        height: 1.24rem;
        margin-bottom: .52rem;
      }
      .text {
        color: #6E6B7C;
        padding-bottom: .10rem;
      }
      .link {
        color: #88B6FD;
      }
    }
    .product-list {
      .product-item {
        background: #1E1E23;
        border-radius: .06rem;
        margin-bottom: .15rem;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .main-img {
          width: 100%;
          height: 2.42rem;
          object-fit: cover;
          border-radius: .06rem;
        }
        .content-super {
          padding: .15rem .12rem;
          position: relative;
          .title {
            font-size: .14rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .20rem;
            padding-bottom: .05rem;
          }
          .time {
            font-size: .14rem;
            font-weight: 400;
            color: #979797;
            line-height: .20rem;
          }
        }
        .sell-status-super {
          position: absolute;
          top: .10rem;
          left: .12rem;
          height: .20rem;
          line-height: .20rem;
          color: #FFFFFF;
          border-radius: .10rem;
          padding: 0 .09rem;
          font-size: .12rem;
          font-weight: 400;
          display: flex;
          align-items: center;
          &.sell-fire {
            background: #FCCA01;
          }
          &.sell-time {
            background: #EF715D;
          }
          &.sell-enpty {
            background: #999999;
          }
          .fire-icon {
            width: .10rem;
            height: .12rem;
            margin-right: .05rem;
          }
          .time-icon {
            width: .12rem;
            height: .12rem;
            margin-right: .05rem;
          }
        }
      }
    }
  }
}
